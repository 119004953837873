<template>
    <v-alert :type="type" border="left" text dense dark>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <div class="text-white mr-1 caption">{{ robotName }}</div>
                <div class="caption">{{ message }}</div>
            </div>

            <div class="d-flex">
                <v-spacer />
                <v-chip color="rgba(30, 30, 30, 0.2)" label small>{{ date }}</v-chip>
            </div>
        </div>
        <NotificationListItemDialog :dialog="isDisplayDialog" @closeDialog="closeDialog()" />
    </v-alert>
</template>

<script>
import NotificationListItemDialog from '@/components/NotificationListItemDialog';

export default {
    name: 'NotificationListItem',
    components: { NotificationListItemDialog },
    props: {
        type: {
            type: String,
            required: true
        },
        robotName: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isDisplayDialog: false
        };
    },
    computed: {},
    methods: {
        openDialog() {
            this.isDisplayDialog = true;
        },
        closeDialog() {
            this.isDisplayDialog = false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
