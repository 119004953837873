import axios from 'axios';
import config from '@/config/apiConfig';

export default {
    get(apiUrl) {
        return axios
            .get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(
                response => {
                    return response;
                },
                error => {
                    return {
                        errors: error,
                    };
                }
            );
    },
    getFromFiware(apiUrl) {
        return axios
            .get(apiUrl, {
                responseType: 'json',
                headers: {
                    'content-type': 'application/json',
                    'fiware-service': config.fiwareService,
                    'fiware-servicepath': config.fiwareServicepath,
                },
            })
            .then(
                response => {
                    return response;
                },
                error => {
                    return {
                        errors: error,
                    };
                }
            );
    },
    postWithBody(apiUrl, body, config = {}) {
        return axios
            .post(apiUrl, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
                ...config,
            })
            .then(
                response => {
                    return response;
                },
                error => {
                    return {
                        errors: error,
                    };
                }
            );
    },
    postWithBodyToFiware(apiUrl, body) {
        return axios
            .post(apiUrl, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'fiware-service': config.fiwareService,
                    'fiware-servicepath': config.fiwareServicepath,
                },
            })
            .then(
                response => {
                    return response;
                },
                error => {
                    return {
                        errors: error,
                    };
                }
            );
    },
};
