import axiosWithoutAuth from '@/lib/axiosWithoutAuth';
import apiConfig from '@/config/apiConfig';

const { poc: pocApiConfig } = apiConfig;
const { host } = pocApiConfig;

export const getRobotInfo = async deviceSn => {
    return await axiosWithoutAuth.get(`${host}/getRobotInfo?deviceSn=${deviceSn}`);
};

// 处理一个报警
export const callTask = async (pointUuid, deviceSn) => {
    return await axiosWithoutAuth.post(
        `${host}/robot/callTask?pointUuid=${pointUuid}&deviceSn=${deviceSn}`
    );
};
