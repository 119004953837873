const stations = [
    {
        text: '站点A',
        value: '22084'
    },
    {
        text: '站点B',
        value: '23910'
    },
    {
        text: '站点C',
        value: '23930'
    }
];
export default stations;
