<template>
    <div id="divPlugin" class="plugin" style="height: 250px;width: 250px; "></div>
    <div v-if="data.img">
        <img :src="data.img" style="width: 300px; height: 250px;" />
    </div>
    <div>
        <button @click="init">初始化</button>
        <button @click="login">登录</button>

        <button @click="startRealPlay">预览</button>

        <button @click="capturePicData">抓图</button>

        <button @click="destroyPlugin">销毁</button>

        <button @click="startPlayback">回放</button>
    </div>
</template>
<script lang="js" setup>
import {
    capturePicData,
    data,
    destroyPlugin,
    init,
    login,
    pausePlayback,
    playbackByTime,
    resumePlayback,
    startPlayback,
    startRealPlay,
    stopPlayback,
} from './index';

// 开始回放（默认回放最近一小时的录像）
startPlayback();

// 停止回放
stopPlayback();


// 按指定时间段回放
playbackByTime('2023-11-22 10:00:00', '2023-11-22 11:00:00');

// 暂停回放
pausePlayback();

// 恢复回放
resumePlayback();
</script>
<style lang="less" scoped></style>;
