<template>
    <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        id="レイヤー_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 28 28"
        style="enable-background: new 0 0 28 28"
        :width="width"
        :height="height"
        xml:space="preserve"
    >
        <circle class="st0" cx="14" cy="14" r="11" />
        <path
            class="st1"
            d="M14,28C6.28,28,0,21.72,0,14S6.28,0,14,0c7.72,0,14,6.28,14,14S21.72,28,14,28z M14,3C7.93,3,3,7.93,3,14
	c0,6.07,4.93,11,11,11c6.07,0,11-4.93,11-11C25,7.93,20.06,3,14,3z"
        />
    </svg>
</template>

<script>
export default {
    name: 'LocationIcon',
    props: {
        width: {
            type: [Number, String],
            default: 28
        },
        height: {
            type: [Number, String],
            default: 28
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.st0 {
    fill: #20e8b6;
}
.st1 {
    fill: #ffffff;
}
</style>
