<template>
    <v-dialog v-model="dialog" max-width="400px">
        <v-card>
            <v-card-title class="headline">呼叫车辆</v-card-title>
            <v-card-text>
                <v-form>
                    <v-select
                        v-model="selectedStation"
                        :items="stations"
                        item-title="text"
                        item-value="value"
                        label="终点"
                    ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :rounded="0"
                    class="text-[#C0C0C0] font-light ms-4 rounded-0"
                    color="#424242"
                    variant="flat"
                    @click="cancel"
                >
                    取消
                </v-btn>
                <v-btn
                    :rounded="0"
                    class="text-[#C0C0C0] font-light ms-4 rounded-0"
                    color="#4558C8"
                    variant="flat"
                    @click="confirmCallTheCar"
                >
                    确认
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { defineExpose, ref } from 'vue';
import stations from '../../const/stations';
import useCallTheCar from '@/views/DealWarnVideoLive/components/CallTheCarModal/hooks/useCallTheCar';

const dialog = ref(false);
const selectedStation = ref(null);

const showModal = () => (dialog.value = true);

defineExpose({ showModal }); // 暴露方法给父组件

const cancel = () => (dialog.value = false);

const { confirmCallTheCar } = useCallTheCar(dialog);
</script>

<style scoped>
.headline {
    display: flex;
    justify-content: space-between;
}
</style>
