import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import usePointPostionPie from './usePointPostionPie';
import useImageSize from '@/hooks/web/useImageSize';

import robotPoint from '@/assets/robots/robotPoint.svg';
import robotPointActive from '@/assets/robots/robotPointActive.svg';

// 定义图标大小常量
const ICON_SIZE = 80;

// 输出图标路径（用于调试）
console.log('robotPoint', robotPoint, robotPointActive);

// 定义画布动画 hook
const useCanvasAnimation = (data, config = {}, canvasRef, scale) => {
    let setIntervalId; // 用于存储 setInterval 的 ID

    let canvas = null; // 存储 canvas 元素

    const pointsToDraw = ref([]); // 存储需要绘制的点

    // 创建普通图标图像对象
    const iconImage = new Image();
    iconImage.src = robotPoint; // 设置图像源
    iconImage.width = ICON_SIZE; // 设置宽度
    iconImage.height = ICON_SIZE; // 设置高度

    // 创建激活状态的图标图像对象
    const activeIconImage = new Image();
    activeIconImage.src = robotPointActive; // 设置图像源
    activeIconImage.width = ICON_SIZE; // 设置宽度
    activeIconImage.height = ICON_SIZE; // 设置高度

    // 组件挂载时执行
    onMounted(async () => {
        // 获取地图图片的尺寸
        const { width: mapImgWidth, height: mapImgHeight } = await useImageSize(
            config.mapImgUrlPath
        );

        console.log('onMounted canvasRef.value', canvasRef.value);
        canvas = canvasRef.value; // 获取 canvas 元素

        // 在下一个 DOM 更新周期中设置 canvas 尺寸
        nextTick(() => {
            canvasRef.value.width = mapImgWidth;
            canvasRef.value.height = mapImgHeight;
        });

        // 监听数据变化
        watch(
            data,
            async () => {
                if (!data.value) return; // 如果没有数据，直接返回
                console.log('pointsToDraw.value 1', data.value); // 输出数据（用于调试）
                const { handlePoint } = await usePointPostionPie(config, canvasRef, scale); // 获取点位置处理函数

                // 每过一秒 放一个新的带点
                // 处理并过滤数据点
                pointsToDraw.value = data.value
                    .map(point => {
                        console.log('point1', point);
                        const startPoint = point.slice(-2)[0];
                        const endPoint = point.slice(-2)[1] || startPoint;

                        console.log('startPoint', startPoint, 'endPoint', endPoint);
                        console.log('pointsToDraw.value 2', startPoint, 'endPoint', endPoint); // 输出数据（用于调试）

                        if (startPoint && endPoint) {
                            return { startPoint, endPoint };
                        }

                        return null;
                    })
                    .filter(point => point !== null)
                    .map(({ startPoint, endPoint }) => {
                        console.log('point2', { startPoint, endPoint });
                        return {
                            startPoint: [...handlePoint(startPoint), startPoint.angle],
                            endPoint: [...handlePoint(endPoint), endPoint.angle]
                        };
                    });

                console.log('pointsToDraw.value 3', pointsToDraw.value); // 输出待绘制的点（用于调试）
                // 如果有点需要绘制，则开始动画
                pointsToDraw.value && animate();
            },
            {
                immediate: true, // 立即执行一次
                deep: true
            }
        );
    });

    // 组件卸载时清除定时器
    onUnmounted(() => {
        clearInterval(setIntervalId);
    });

    // 缓动函数
    function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    // 绘制动画函数
    const animate = () => {
        const ctx = canvas?.getContext('2d'); // 获取 2D 绘图上下文
        console.log('animate', ctx, canvas);
        if (!ctx || !canvas) return; // 如果没有上下文或 canvas，直接返回
        const startTime = new Date().getTime(); // 记录开始时间

        console.log('canvas.width', canvas);
        const step = () => {
            const currentTime = new Date().getTime(); // 获取当前时间
            const progress = (currentTime - startTime) / 500; // 计算动画进度

            if (progress <= 1) {
                if (pointsToDraw.value.length > 0) {
                    // 清除画布
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }

                // 绘制所有点
                pointsToDraw.value.forEach(point => {
                    const { startPoint, endPoint } = point;
                    const [startX, startY] = startPoint; // 获取起始点坐标
                    // const [targetX, targetY] = endPoint; // 获取目标点坐标
                    const [targetX, targetY, angle] = endPoint; // 获取目标点坐标
                    // const [targetX, targetY] = endPoint; // 获取目标点坐标
                    // const [targetX, targetY] = endPoint; // 获取目标点坐标

                    // 计算距离
                    const dx = targetX - startX;
                    const dy = targetY - startY;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    ctx.save(); // 保存当前绘图状态

                    let finallyX = startX;
                    let finallyY = startY;

                    if (distance > 0) {
                        // 计算当前位置
                        const step = progress * distance;
                        const factor = step / distance;
                        finallyX = startX + dx * factor;
                        finallyY = startY + dy * factor;
                    }

                    ctx.save(); // 再次保存绘图状态

                    // 平移画布到图片中心
                    ctx.translate(finallyX, finallyY);

                    // 旋转画布
                    // ctx.rotate(((config.initRoute - angle) * Math.PI) / 180) *
                    //     easeInOutCubic(progress);

                    // 旋转画布
                    ctx.rotate((-(config.initRoute - angle) * Math.PI) / 180) *
                        easeInOutCubic(progress);

                    // 绘制图像
                    ctx.drawImage(
                        activeIconImage,
                        -ICON_SIZE / 2, // 图片的中心对齐画布的坐标原点
                        -ICON_SIZE / 2,
                        ICON_SIZE,
                        ICON_SIZE
                    );

                    ctx.restore(); // 恢复之前保存的绘图状态
                });

                requestAnimationFrame(step); // 请求下一帧动画
            }
        };

        step(); // 开始动画循环
    };

    // 返回 canvas 引用和待绘制的点
    return {
        canvasRef,
        pointsToDraw
    };
};

export default useCanvasAnimation; // 导出 hook
