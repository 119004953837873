<template>
    <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        id="レイヤー_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 11.83 21.7"
        style="enable-background: new 0 0 11.83 21.7"
        :width="width"
        :height="height"
        :fill="color"
        xml:space="preserve"
    >
        <path
            id="パス_1362"
            transform="matrix(0,-1,1,0,-5,16.7)"
            d="M 2.5 0.4 L 11.4 9.8 c 0.6 0.6 0.6 1.5 0 2.1 l -8.9 9.4 c -0.5 0.6 -1.4 0.6 -2 0.1 c 0 0 0 0 -0.1 -0.1 c -0.6 -0.5 -0.7 -1.4 -0.1 -2 c 0 0 0.1 -0.1 0.1 -0.1 l 7.9 -8.3 L 0.4 2.5 C -0.1 2 -0.1 1.1 0.4 0.5 c 0 0 0.1 -0.1 0.1 -0.1 c 0.5 -0.6 1.4 -0.6 2 -0.1 C 2.5 0.4 2.5 0.4 2.5 0.4 z"
        />
    </svg>
</template>

<script>
export default {
    name: 'ArrowIcon',
    props: {
        width: {
            type: [Number, String],
            default: 20
        },
        height: {
            type: [Number, String],
            default: 16
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
