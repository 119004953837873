import { onMounted, ref } from 'vue';
import * as warnService from '@/services/warnService';

const useWarnInfoData = alertTimeId => {
    const currentWarnData = ref({
        alarmProcess: '01',
        alarmProcessName: '未处理',
    });

    const fetchCurrentWarnData = async () => {
        const { data = [] } = (await warnService.getOne(alertTimeId)) || {};

        currentWarnData.value = {
            ...data.data,
            alertTimeId: alertTimeId,
        };
    };

    onMounted(async () => {
        await fetchCurrentWarnData();
    });

    const updateCurrentWarn = warnItem => (currentWarnData.value = warnItem);

    return {
        currentWarnData,
        updateCurrentWarn,
    };
};

export default useWarnInfoData;
