const config = {
    alertCount: 2,
    color: {
        theme: ['#FEEF77', '#20E8B6', '#FE8966'],
        battery: {
            high: '#20E8B6',
            middle: '#FEEF77',
            low: '#FE8966'
        },
        status: {
            frutera: {
                init: '#20E8B6',
                busy: '#20E8B6',
                ready: '#20E8B6',
                charging: '#20E8B6',
                movingpos: '#20E8B6',
                workingpos: '#20E8B6',
                stop: '#FE8966'
            },
            temi: {
                start: '#20E8B6',
                going: '#20E8B6',
                'obstacle detected': '#20E8B6',
                'node inactive': '#FE8966',
                calculating: '#20E8B6',
                complete: '#20E8B6',
                abort: '#FE8966'
            }
        },
        command: {
            frutera: {
                init: '#20E8B6',
                busy: '#20E8B6',
                ready: '#20E8B6',
                charging: '#20E8B6',
                movingpos: '#FEEF77',
                workingpos: '#FEEF77',
                stop: '#FE8966'
            },
            temi: {
                start: '#FEEF77',
                going: '#FEEF77',
                'obstacle detected': '#FEEF77',
                'node inactive': '#FE8966',
                calculating: '#20E8B6',
                complete: '#20E8B6',
                abort: '#FE8966'
            }
        }
    },
    text: {
        status: {
            frutera: {
                init: '正常',
                busy: '正常',
                ready: '正常',
                charging: '正常',
                movingpos: '正常',
                workingpos: '正常',
                stop: '異常'
            },
            temi: {
                start: '正常',
                going: '正常',
                'obstacle detected': '正常',
                'node inactive': '異常',
                calculating: '正常',
                complete: '正常',
                abort: '異常'
            }
        },
        command: {
            frutera: {
                init: '起動中',
                busy: '配送準備中',
                ready: '待機中',
                charging: '充電中',
                movingpos: '配送中',
                workingpos: '配送完了',
                stop: '配送停止'
            },
            temi: {
                start: 'ご案内中',
                going: 'ご案内中',
                'obstacle detected': 'ご案内中',
                'node inactive': 'システムエラー',
                calculating: 'ご案内準備中',
                complete: '待機中',
                abort: 'ご案内中断'
            }
        },
        description: {
            frutera:
                'ドリンクを会議室へ配送するロボットです。会議室内のスマートスピーカーからご注文いただくことができます。',
            temi:
                '会議室へお客様をご案内するロボットです。画面に表示されている会議室をタップするとご案内を開始します。'
        },
        information:
            'お客様のオフィスでもロボットを活用することができます。 \n詳しくは右のQRコードからお問い合わせください。'
    },
    location: {
        Home: {
            left: '88.5',
            bottom: '92'
        }
    },
    translateParameters: {
        frutera: {
            a: -0.5410179833,
            b: -1.5435603138,
            c: -1 * -4.0718745988, // attribute `bottom`に設定するため、エクセルでの導出結果に-1を掛ける
            d: -1.7228923931,
            e: 71.93410394,
            f: 36.2842463331,
            rot: -20
        },
        temi0000: {
            a: 0.0990638818,
            b: -1.6585123068,
            c: -1 * -4.7854371577, // attribute `bottom`に設定するため、エクセルでの導出結果に-1を掛ける
            d: 0.0106081687,
            e: 22.8334026743,
            f: 100.6812786419,
            rot: 0
        },
        temi0001: {
            a: 0.2449578374,
            b: -1.6058297097,
            c: -1 * -4.3930656264, // attribute `bottom`に設定するため、エクセルでの導出結果に-1を掛ける
            d: -0.2184926294,
            e: 29.3506329815,
            f: 99.9476369209,
            rot: 0
        }
    }
};

module.exports = config;
