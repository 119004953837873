<template>
    <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        id="レイヤー_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1345.9 588"
        style="enable-background: new 0 0 1345.9 588"
        :width="width"
        :height="height"
        xml:space="preserve"
    >
        <path id="パス_33" class="st0" d="M217.7,587H22l-4.3-120.7l200.1-6.5L217.7,587z" />
        <path id="パス_34" class="st0" d="M334,587H217.7V459.8l106.5-2.9L334,587z" />
        <path id="パス_35" class="st0" d="M427.2,587H334l-9.8-130.1l106.9-7.8L427.2,587z" />
        <path id="パス_36" class="st0" d="M556.1,587H427.2l3.8-137.8l116.5,7.8L556.1,587z" />
        <path
            id="パス_37"
            class="st1"
            d="M140.6,527.6c0.1,7.9-6.3,14.4-14.2,14.5c-7.9,0.1-14.4-6.3-14.5-14.2
	c-0.1-7.9,6.3-14.4,14.2-14.5c0.1,0,0.1,0,0.2,0C134.2,513.4,140.5,519.7,140.6,527.6 M113.8,527.6c0,6.9,5.6,12.6,12.5,12.6
	s12.6-5.6,12.6-12.5s-5.6-12.6-12.5-12.6c0,0,0,0,0,0C119.4,515.1,113.8,520.7,113.8,527.6"
        />
        <path
            id="パス_38"
            class="st1"
            d="M271.9,539.9h-1.8v-27.6h4.9c2.2,0,4.2,0.2,6,1.6c3.1,2.8,3.3,7.7,0.5,10.8
	c-0.1,0.1-0.2,0.2-0.3,0.3c-1.7,1.5-4,1.8-6.1,1.8h-3.1L271.9,539.9L271.9,539.9z M275.1,525.2c1.7,0.2,3.5-0.4,4.8-1.5
	c1.2-1,1.8-2.6,1.8-4.1c0-1.7-0.7-3.4-2.1-4.5c-1.4-1.1-3.1-1.1-4.8-1.1h-2.9v11.2H275.1z"
        />
        <path
            id="パス_39"
            class="st1"
            d="M386.6,533.1c-2.5,2.1-5.7,3.2-9,3.3c-7.8-0.1-14.1-6.5-14-14.3s6.5-14.1,14.3-14
	c7.7,0.1,14,6.3,14,14c0,3.7-1.5,7.3-4,10l3.6,3.9h-2.4L386.6,533.1z M386.6,530.8c2.2-2.2,3.5-5.2,3.5-8.3
	c0.4-7.1-5.1-13.2-12.3-13.5s-13.2,5.2-13.5,12.3c-0.4,7.1,5.1,13.2,12.3,13.5c3.2,0.2,6.3-0.9,8.8-2.9l-6.9-7.1h2.5L386.6,530.8z"
        />
        <path
            id="パス_40"
            class="st1"
            d="M499.4,536h-2l-9-13.1h-0.9V536h-1.8v-27.6h3.4c2.2,0,4.4,0,6.3,1.4c1.9,1.4,2.9,3.6,2.8,5.9
	c0,4.8-3.3,7.3-7.9,7.2L499.4,536z M489.7,521.3c1.7,0,3.4-0.1,4.8-1.3c1.3-1.1,2-2.7,1.9-4.3c0.1-2.1-1-4-2.9-5
	c-1.6-0.6-3.3-0.8-4.9-0.6h-1.1v11.2H489.7z"
        />
        <path id="パス_41" class="st0" d="M900.6,587H731.9l3.3-118L892,457L900.6,587z" />
        <path
            id="パス_42"
            class="st0"
            d="M1053.1,587H900.6L892,456.9l28.7-4l120.9,34.5L1053.1,587z"
        />
        <path
            id="パス_43"
            class="st0"
            d="M1195.8,587h-142.7l-11.5-99.6l122.6-54.1l31.3,66.4L1195.8,587z"
        />
        <path
            id="パス_44"
            class="st0"
            d="M1344.9,587h-149.1l-0.3-87.3l-31.3-66.4l60.4-27h61l59.3,144.5L1344.9,587z"
        />
        <path
            id="パス_45"
            class="st1"
            d="M823.8,512.3h1.8v17.2c0,2.8,0,5.2-1.9,7.5c-3.9,4.1-10.5,4.3-14.6,0.4
	c-0.1-0.1-0.2-0.2-0.4-0.4c-1.9-2.3-1.9-4.7-1.9-7.5v-17.2h1.8V529c0,2.5,0,5.1,1.4,7c3.2,3.4,8.6,3.6,12,0.3
	c0.1-0.1,0.2-0.2,0.3-0.3c1.4-1.8,1.4-4.5,1.4-7L823.8,512.3z"
        />
        <path
            id="パス_46"
            class="st1"
            d="M972.5,541.3L972.5,541.3l8.2-23.6h1.8l-10,28.5l-9.9-28.5h1.8L972.5,541.3z"
        />
        <path
            id="パス_47"
            class="st1"
            d="M1108.5,531.7l10.3-25.1l10.3,25.1l7.7-23.7h1.8l-9.3,28.5l-10.5-25.5l-10.5,25.5l-9.2-28.5h1.8
	L1108.5,531.7z"
        />
        <path
            id="パス_48"
            class="st1"
            d="M1246.2,494.4h1.9l6.3,11.9l6.3-11.9h1.9l-7.2,13.5l7.6,14.1h-1.9l-6.7-12.5l-6.7,12.5h-1.9
	l7.5-14.1L1246.2,494.4z"
        />
        <rect id="長方形_31" x="388.5" y="1.3" class="st0" width="88.9" height="97.9" />
        <path id="パス_49" class="st0" d="M588.2,99.2h-31.7V1.3h42.3L588.2,99.2z" />
        <rect id="長方形_32" x="477.4" y="1.3" class="st0" width="79.1" height="97.9" />
        <path
            id="パス_50"
            class="st1"
            d="M520.2,33.2v27.1c0,3.4-0.8,6.3-4.9,6.3c-2,0-3.8-1.2-4.6-3l1.3-1c0.6,1.4,1.9,2.3,3.4,2.3
	c2.7,0,3-2.6,3-4.7V33.2H520.2z"
        />
        <path
            id="パス_51"
            class="st1"
            d="M440.2,33.2h2.3l-13.5,13.3l14.3,14.3h-2.3l-13.2-13.2l-0.7,0.7v12.4h-1.8V33.2h1.8v12.9
	L440.2,33.2z"
        />
        <rect id="長方形_33" x="700.4" y="1.3" class="st0" width="86" height="97.9" />
        <rect id="長方形_34" x="786.4" y="1.3" class="st0" width="82.8" height="97.9" />
        <rect id="長方形_35" x="869.2" y="1.3" class="st0" width="82.8" height="97.9" />
        <path id="パス_52" class="st0" d="M1095.8,99.2h-59.1V1.3h90.6v66.9L1095.8,99.2z" />
        <path id="パス_53" class="st0" d="M1186.4,68.2h-59.1V1.3h101.9v41.8L1186.4,68.2z" />
        <rect id="長方形_36" x="951.9" y="1.3" class="st0" width="84.8" height="97.9" />
        <path
            id="パス_54"
            class="st1"
            d="M1093.4,38.8c-2.3-2.7-5.7-4.3-9.3-4.4c-6.9,0.1-12.5,5.8-12.6,12.7c0,3.2,1.4,6.3,3.7,8.5
	c2.4,2.3,5.5,3.7,8.8,3.8c3.5-0.1,6.9-1.6,9.3-4.2v2.4c-6,5.1-15,4.4-20.1-1.5c-2.2-2.5-3.4-5.8-3.4-9.2c0.1-7.9,6.5-14.2,14.4-14.2
	c3.4,0,6.7,1.3,9.2,3.6V38.8z"
        />
        <path
            id="パス_55"
            class="st1"
            d="M985.3,33.2h5c4,0,7.2,0.7,10.2,3.5c5.7,5.5,5.9,14.5,0.4,20.2c-0.1,0.1-0.2,0.2-0.3,0.3
	c-3,2.9-6.3,3.7-10.4,3.7h-5L985.3,33.2L985.3,33.2z M990.3,59.2c3.6,0,6.6-0.7,9.3-3.3c4.9-4.9,4.9-12.8,0-17.6
	c-0.1-0.1-0.2-0.2-0.3-0.3c-2.6-2.4-5.5-3-8.9-3h-3.2v24.3L990.3,59.2z"
        />
        <path
            id="パス_56"
            class="st1"
            d="M757.2,47.5c0.1,3.5-0.4,6.5-2.9,9.2c-5.4,5.7-14.4,6-20.2,0.6c-2.8-2.6-4.4-6.3-4.5-10.2
	c0-7.9,6.5-14.3,14.4-14.3c5,0,9.1,2.5,12,6.4l-1.4,1.1c-2.3-3.7-6.3-5.9-10.7-5.9c-6.8,0.2-12.3,5.7-12.5,12.5
	c0,6.9,5.6,12.5,12.5,12.5c3-0.1,5.8-1.1,8.1-3c2.2-1.8,3.4-4.5,3.4-7.3h-9.9v-1.7L757.2,47.5L757.2,47.5z"
        />
        <path
            id="パス_57"
            class="st1"
            d="M824.4,34.8v10h9.1v1.7h-9.1v14.3h-1.8V33.2h11.1v1.7L824.4,34.8z"
        />
        <path
            id="パス_58"
            class="st1"
            d="M906,34.8v10h11.1v1.7H906v12.6h11.4v1.7h-13.2V33.2h13.2v1.7L906,34.8z"
        />
        <path id="パス_59" class="st0" d="M411,369l-106.6,5.6L293,198.8l109.8-5.1L411,369z" />
        <path id="パス_60" class="st0" d="M518.3,364L411,369l-8.1-175.3l106.6-4.9L518.3,364z" />
        <path
            id="パス_61"
            class="st0"
            d="M625.7,358.9L518.3,364l-8.9-175.2l107.8-5.9L625.7,358.9z"
        />
        <path
            id="パス_62"
            class="st1"
            d="M565.9,261.1L565.9,261.1l-9.7,23.8l-9.6-23.8h-0.1l-4.7,22.8h-1.7l5.9-28.5l10.1,25.1
	l10.2-25.1l5.9,28.5h-1.7L565.9,261.1z"
        />
        <path id="パス_63" class="st1" d="M579.6,258l0.8-1.6h5.3V284h-1.8V258H579.6z" />
        <path
            id="パス_64"
            class="st1"
            d="M458.9,266.6L458.9,266.6l-9.7,23.8l-9.6-23.8h-0.1l-4.7,22.8h-1.7l5.9-28.5l10.1,25.1
	l10.2-25.1l5.9,28.5h-1.7L458.9,266.6z"
        />
        <path
            id="パス_65"
            class="st1"
            d="M485.2,287.8v1.6h-17l10.3-11.3c2.1-2.3,5.1-5.5,5.1-8.7c0-3.5-2.9-6.4-6.5-6.3c0,0,0,0,0,0
	c-3.5-0.1-6.4,2.5-6.5,6c0,0.2,0,0.4,0,0.5h-1.8c-0.1-1.9,0.5-3.8,1.7-5.2c1.7-1.8,4.1-2.9,6.5-2.9c4.5-0.1,8.2,3.4,8.3,7.9
	c0,0.1,0,0.1,0,0.2c0,3.7-2.8,6.7-5.2,9.3l-8,8.9L485.2,287.8z"
        />
        <path
            id="パス_66"
            class="st1"
            d="M350.3,271.9L350.3,271.9l-9.7,23.8l-9.6-23.8H331l-4.7,22.8h-1.7l5.9-28.5l10.1,25.1l10.2-25.1
	l5.9,28.5h-1.7L350.3,271.9z"
        />
        <path
            id="パス_67"
            class="st1"
            d="M368.1,279.5c3.1,0.1,5.8-2.4,5.9-5.6c0-0.1,0-0.1,0-0.2c0-3-2.5-5.4-5.5-5.4
	c-3.2,0-5.2,2.1-5.3,5.2h-1.8c0.3-4.2,2.9-6.9,7.1-6.9c3.9,0,7.1,3.1,7.2,7c0,0,0,0,0,0.1c0,2.8-1.6,5.3-4.1,6.4
	c2.8,1.1,4.5,3.8,4.5,6.7c0.1,4.4-3.4,8-7.8,8.1c-0.1,0-0.2,0-0.3,0c-4.1,0-7.7-2.9-7.6-7.1h1.8c0.1,3.1,2.7,5.6,5.9,5.5
	c3.5,0,6.3-2.9,6.4-6.4c0-3.6-2.8-5.9-6.3-5.9V279.5z"
        />
        <path id="パス_68" class="st0" d="M208.5,117.2H5.2L1,1.3h199.4L208.5,117.2z" />
        <path id="パス_69" class="st0" d="M259.4,84.9l-53.9-10.8l-5.1-72.8h57.9L259.4,84.9z" />
        <path id="パス_70" class="st0" d="M334.4,99.2l-75.1-14.3l-1.1-83.6h76.2V99.2z" />
        <path id="パス_71" class="st0" d="M214.8,217.1H8.7l-3.6-100h203.4L214.8,217.1z" />
        <path id="パス_72" class="st0" d="M222.7,346.6l-210-18.1l-4-111.4h206.1L222.7,346.6z" />
        <path id="パス_73" class="st1" d="M295.1,59.2h7v1.7h-8.8V33.2h1.8L295.1,59.2z" />
        <path
            id="パス_74"
            class="st1"
            d="M111.7,70.7L88.6,46v23.7h-1.8v-28L110,66.2V42.2h1.8V70.7z"
        />
        <path id="パス_75" class="st1" d="M121.6,43.8l0.8-1.7h5.3v27.6H126V43.8H121.6z" />
        <path
            id="パス_76"
            class="st1"
            d="M114.9,178.6L91.7,154v23.7H90v-28l23.2,24.4v-24.1h1.8V178.6z"
        />
        <path
            id="パス_77"
            class="st1"
            d="M137.4,176v1.6h-17l10.3-11.3c2.1-2.3,5.1-5.4,5.1-8.7c0-3.5-2.9-6.4-6.5-6.3c0,0,0,0-0.1,0
	c-3.4-0.1-6.4,2.5-6.5,6c0,0.2,0,0.4,0,0.6h-1.8c-0.1-1.9,0.5-3.8,1.7-5.2c1.7-1.8,4.1-2.9,6.6-2.9c4.5-0.1,8.2,3.4,8.3,7.9
	c0,0.1,0,0.1,0,0.2c0,3.7-2.8,6.7-5.2,9.3l-8,8.9H137.4z"
        />
        <path
            id="パス_78"
            class="st1"
            d="M118.8,293.3l-23.2-24.6v23.7h-1.8v-28l23.2,24.4v-24.1h1.8L118.8,293.3L118.8,293.3z"
        />
        <path
            id="パス_79"
            class="st1"
            d="M132.8,277.2c3.1,0.1,5.8-2.4,5.9-5.6c0-0.1,0-0.1,0-0.2c0-3-2.5-5.4-5.4-5.4
	c-3.2,0-5.2,2.1-5.3,5.2h-1.8c0.3-4.2,2.8-6.9,7.1-6.9c3.9,0,7.1,3.1,7.2,7c0,0,0,0,0,0.1c0,2.8-1.6,5.3-4.1,6.4
	c2.8,1.1,4.6,3.8,4.5,6.7c0.1,4.4-3.4,8-7.8,8.1c-0.1,0-0.2,0-0.3,0c-4.1,0-7.7-2.9-7.7-7.1h1.8c0.1,3.1,2.7,5.6,5.9,5.5
	c3.5,0,6.3-2.9,6.4-6.4c0-3.6-2.8-5.9-6.3-5.9L132.8,277.2z"
        />
        <path
            id="パス_80"
            class="st0"
            d="M1112.7,286.9l-93.6,44.5l-35.7-86.1l93.1-43.7L1112.7,286.9z"
        />
        <path
            id="パス_81"
            class="st0"
            d="M1147.2,366.7l-93.2,42.8l-35-78.1l93.6-44.5L1147.2,366.7z"
        />
        <path
            id="パス_82"
            class="st0"
            d="M1230.8,232.1l-118.2,54.8l-36.1-85.4l118.2-54.8L1230.8,232.1z"
        />
        <path
            id="パス_83"
            class="st0"
            d="M1265.4,310.6l-118.2,56.1l-34.6-79.8l118.2-54.8L1265.4,310.6z"
        />
        <path
            id="パス_84"
            class="st1"
            d="M1147.6,218.2l-3.7,9.1h-1.8l11.6-28.5l11.5,28.5h-1.8l-3.7-9.1H1147.6z M1153.7,203.3
	l-5.4,13.3h10.8L1153.7,203.3z"
        />
        <path
            id="パス_85"
            class="st1"
            d="M1073.1,331.1h1.9l8.1,14l8.1-14h1.9l-9.2,15.8v11.8h-1.8v-11.8L1073.1,331.1z"
        />
        <path
            id="パス_86"
            class="st1"
            d="M1198.5,308.2v1.6h-19.4l16.8-26h-15.2v-1.6h18.3l-16.8,26H1198.5z"
        />
        <path
            id="パス_87"
            class="st1"
            d="M1040.6,277v-27.6h5.5c2.1,0,4.1,0.1,5.7,1.6c1.6,1.5,2.4,3.6,2.3,5.7c0.1,2.3-1.1,4.5-3.2,5.6
	c3.3,0.6,5.8,3.5,5.7,7c0.1,2.2-0.9,4.3-2.6,5.7c-1.9,1.6-4,2-6.4,2L1040.6,277L1040.6,277z M1042.4,261.9h3c1.9,0,3.4,0.1,4.9-1
	c1.4-1,2.2-2.7,2.1-4.4c0.1-1.8-0.8-3.5-2.3-4.5c-1.5-0.7-3.1-1-4.7-0.9h-3V261.9z M1042.4,275.3h4.2c1.9,0,3.9,0.1,5.6-1.1
	c1.7-1.1,2.7-2.9,2.7-4.9c0.1-2-1-4-2.8-4.9c-1.8-0.7-3.8-1-5.8-0.9h-3.9L1042.4,275.3L1042.4,275.3z"
        />
        <path
            id="パス_88"
            class="st0"
            d="M1327.7,281.4l-32.1,17.8L1227,133.3l31.6-17.1L1327.7,281.4z"
        />
        <path id="パス_89" class="st0" d="M788.3,284.5l-83-4.7l9.2-91.9l81,6.7L788.3,284.5z" />
        <path id="パス_90" class="st0" d="M925.2,295l-136.8-10.5l7.2-89.9L929.3,206L925.2,295z" />
        <path id="パス_91" class="st0" d="M920.1,385.8l-140.3-11.3l8.6-90L925.2,295L920.1,385.8z" />
        <path id="パス_92" class="st0" d="M779.7,374.6l-83-6.9l8.6-87.8l83,4.7L779.7,374.6z" />
        <rect id="長方形_37" x="749.5" y="219.2" class="st1" width="1.8" height="27.6" />
        <path
            id="パス_93"
            class="st1"
            d="M853.3,345.7h-1.8v-25.9h-7.1v-1.6h16v1.6h-7.1L853.3,345.7z"
        />
        <path
            id="パス_94"
            class="st1"
            d="M748.3,314.7c-1.1-2-3.3-3.3-5.6-3.3c-2.9,0-5.2,2.3-5.2,5.2c0,0,0,0.1,0,0.1
	c0,2.9,2.7,4.1,5,5.2l5.3,2.7c2,1.1,3.2,3.2,3.2,5.5c0,4.6-4.2,8-8.6,8c-3.7,0-7-2.4-8.1-5.9l1.5-0.7c1.2,2.9,3.3,5,6.7,5
	c3.7,0,6.7-2.9,6.8-6.5c0-3.1-3.2-4.4-5.6-5.6l-5-2.6c-1.8-1-2.9-2.9-2.8-4.9c-0.1-3.7,2.8-6.9,6.5-7c0.2,0,0.4,0,0.6,0
	c2.8,0,5.3,1.5,6.7,3.9L748.3,314.7z"
        />
        <path
            id="パス_100"
            class="st1"
            d="M656.8,422.2c-1.2-1.4-2.9-2.2-4.7-2.2c-3.5,0.1-6.3,2.9-6.3,6.4c0,1.6,0.7,3.2,1.9,4.3
	c1.2,1.2,2.8,1.9,4.5,1.9c1.8,0,3.5-0.8,4.7-2.1v1.2c-3,2.6-7.5,2.3-10.2-0.7c-2.6-3-2.3-7.5,0.7-10.1c1.3-1.2,3.1-1.8,4.8-1.8
	c1.7,0,3.4,0.7,4.6,1.8L656.8,422.2L656.8,422.2z"
        />
        <path
            id="パス_101"
            class="st1"
            d="M660.7,428.8l-1.9,4.6H658l5.8-14.4l5.8,14.4h-0.9l-1.9-4.6H660.7z M663.8,421.2l-2.7,6.7h5.5
	L663.8,421.2z"
        />
        <path
            id="パス_102"
            class="st1"
            d="M683.8,433.8l-11.7-12.5v12h-0.9v-14.1l11.7,12.4v-12.2h0.9V433.8z"
        />
        <path
            id="パス_103"
            class="st1"
            d="M688.2,428.8l-1.9,4.6h-0.9l5.8-14.4l5.8,14.4h-0.9l-1.9-4.6H688.2z M691.3,421.2l-2.7,6.7h5.5
	L691.3,421.2z"
        />
        <path id="パス_104" class="st1" d="M699.6,432.5h3.6v0.8h-4.5v-14h0.9V432.5z" />
        <path
            id="パス_110"
            class="st1"
            d="M935.7,145.9c-1.2-1.4-2.9-2.2-4.7-2.2c-3.5,0.1-6.3,2.9-6.3,6.4c0,1.6,0.7,3.2,1.9,4.3
	c1.2,1.2,2.8,1.9,4.5,1.9c1.8-0.1,3.5-0.8,4.7-2.1v1.2c-3,2.6-7.5,2.3-10.2-0.7s-2.3-7.5,0.7-10.1c1.3-1.2,3.1-1.8,4.8-1.8
	c1.7,0,3.4,0.7,4.6,1.8L935.7,145.9L935.7,145.9z"
        />
        <path
            id="パス_111"
            class="st1"
            d="M939.7,152.5l-1.9,4.6h-0.9l5.8-14.4l5.8,14.4h-0.9l-1.9-4.6L939.7,152.5L939.7,152.5z
	 M942.7,144.9l-2.7,6.7h5.5L942.7,144.9z"
        />
        <path
            id="パス_112"
            class="st1"
            d="M962.8,157.5L951,145.1v12h-0.9v-14.2l11.7,12.4v-12.2h0.9V157.5z"
        />
        <path
            id="パス_113"
            class="st1"
            d="M967.1,152.5l-1.9,4.6h-0.9l5.8-14.4L976,157h-0.9l-1.9-4.6L967.1,152.5L967.1,152.5z
	 M970.2,144.9l-2.7,6.7h5.5L970.2,144.9z"
        />
        <path id="パス_114" class="st1" d="M978.5,156.2h3.6v0.8h-4.5v-14h0.9V156.2z" />
        <path
            id="パス_115"
            class="st1"
            d="M1289.7,165.9l-0.4-0.9l4.2-6.8l-0.2-0.4l-6.1,2.6l-0.3-0.8l12.8-5.6l0.7,1.6
	c0.6,1,0.8,2.1,0.6,3.2c-0.3,1.1-1.1,2.1-2.2,2.5c-2.2,1-4.1-0.1-4.9-2.2L1289.7,165.9z M1294.6,158.4c0.3,0.8,0.8,1.5,1.6,2
	c0.8,0.4,1.7,0.4,2.4,0c1-0.4,1.7-1.3,1.7-2.4c-0.1-0.9-0.3-1.7-0.7-2.4l-0.2-0.5l-5.2,2.3L1294.6,158.4z"
        />
        <path
            id="パス_116"
            class="st1"
            d="M1303,163.4l-4.7,2l2.2,5.2l-0.8,0.3l-2.2-5.2l-5.9,2.6l2.3,5.3l-0.8,0.3l-2.7-6.1l12.8-5.6
	l2.7,6.1l-0.8,0.3L1303,163.4z"
        />
        <path
            id="パス_117"
            class="st1"
            d="M1308.9,182.2c0.8-1.6,0.8-3.5,0.2-5.2c-1.5-3.2-5.2-4.6-8.4-3.3c-1.5,0.7-2.7,1.9-3.2,3.4
	c-0.6,1.6-0.6,3.3,0,4.9c0.8,1.6,2.1,2.9,3.8,3.4l-1.1,0.5c-3.6-1.7-5.1-6-3.4-9.6c1.7-3.6,6-5.1,9.6-3.4c1.6,0.8,2.9,2.1,3.6,3.7
	c0.7,1.6,0.7,3.4,0.2,5L1308.9,182.2z"
        />
        <path
            id="パス_118"
            class="st1"
            d="M1312.2,184.6l-4.7,2l2.2,5.2l-0.8,0.3l-2.2-5.2l-5.9,2.6l2.3,5.3l-0.8,0.3l-2.7-6.1l12.8-5.6
	l2.7,6.1l-0.8,0.3L1312.2,184.6z"
        />
        <path
            id="パス_119"
            class="st1"
            d="M1303.9,198.4l-0.3-0.8l12.8-5.6l1,2.3c0.5,0.9,0.7,2,0.4,3.1c-0.7,2-2.9,3.1-4.9,2.4
	c-0.1,0-0.1-0.1-0.2-0.1c-1-0.6-1.7-1.4-2.1-2.5l-0.6-1.4L1303.9,198.4z M1311.3,196.9c0.3,0.8,0.9,1.5,1.7,1.9
	c0.7,0.3,1.6,0.3,2.3,0c0.8-0.3,1.4-1,1.7-1.9c0.1-0.9,0-1.7-0.5-2.5l-0.6-1.3l-5.2,2.3L1311.3,196.9z"
        />
        <path
            id="パス_120"
            class="st1"
            d="M1308.2,208.5l-0.3-0.8l12.1-5.2l-1.4-3.3l0.8-0.3l3.2,7.4l-0.8,0.3l-1.4-3.3L1308.2,208.5z"
        />
        <rect
            id="長方形_38"
            x="1310"
            y="210.4"
            transform="matrix(0.917 -0.399 0.399 0.917 25.2241 542.9523)"
            class="st1"
            width="14"
            height="0.9"
        />
        <path
            id="パス_121"
            class="st1"
            d="M1323.7,226.6c-3.7,1.6-7.9,0-9.6-3.7c-1.6-3.6,0-7.9,3.7-9.6c3.7-1.6,7.9,0,9.6,3.7
	c0,0,0,0.1,0,0.1C1329,220.7,1327.3,224.9,1323.7,226.6 M1318.3,214.1c-3.2,1.4-4.7,5.1-3.3,8.4s5.1,4.7,8.3,3.3s4.7-5.1,3.3-8.4
	c0,0,0,0,0,0C1325.2,214.2,1321.5,212.8,1318.3,214.1"
        />
        <path
            id="パス_122"
            class="st1"
            d="M1322.8,243.1l6.8-15.7l-11,4.8l-0.3-0.8l13-5.6l-6.7,15.7l11.2-4.9l0.3,0.8L1322.8,243.1z"
        />
        <path
            id="パス_123"
            class="st1"
            d="M666.3,225.8c1.4-1.2,2.2-2.9,2.2-4.7c-0.1-3.5-2.9-6.3-6.4-6.4c-1.6,0-3.2,0.7-4.3,1.9
	c-1.2,1.2-1.9,2.8-1.9,4.5c0,1.8,0.8,3.5,2.1,4.7h-1.2c-2.6-3-2.3-7.5,0.7-10.1s7.5-2.3,10.2,0.7c1.2,1.3,1.8,3.1,1.8,4.8
	c0,1.7-0.7,3.4-1.8,4.6L666.3,225.8z"
        />
        <path
            id="パス_124"
            class="st1"
            d="M655.2,235.3v-1l6.6-4.6v-0.4h-6.6v-0.9h14v1.7c0.1,1.1-0.1,2.2-0.7,3.2
	c-0.7,0.9-1.8,1.5-3,1.4c-2.4,0-3.7-1.7-3.7-4L655.2,235.3z M662.6,230.4c-0.1,0.9,0.1,1.7,0.7,2.4c0.5,0.6,1.4,1,2.2,1
	c1.1,0.1,2-0.5,2.5-1.5c0.3-0.8,0.4-1.6,0.3-2.5v-0.6h-5.7L662.6,230.4z"
        />
        <path
            id="パス_125"
            class="st1"
            d="M662.2,251.1c-4,0-7.3-3.2-7.3-7.2s3.2-7.3,7.2-7.3s7.3,3.2,7.3,7.2c0,0,0,0.1,0,0.1
	C669.3,247.8,666.1,251,662.2,251.1 M662.2,237.5c-3.5,0-6.4,2.8-6.4,6.3s2.8,6.4,6.3,6.4s6.4-2.8,6.4-6.3c0,0,0,0,0,0
	C668.5,240.4,665.7,237.5,662.2,237.5"
        />
        <path
            id="パス_126"
            class="st1"
            d="M666.9,259.7c1-0.6,1.7-1.7,1.7-2.8c0-1.4-1.2-2.6-2.6-2.6c0,0,0,0,0,0c-1.5,0-2.1,1.4-2.6,2.5
	l-1.4,2.7c-0.5,1-1.6,1.6-2.8,1.6c-2.4-0.2-4.2-2.2-4-4.6c0.1-1.8,1.3-3.3,3-3.8l0.3,0.8c-1.5,0.4-2.5,1.8-2.5,3.4
	c0,1.9,1.4,3.4,3.3,3.5c1.6,0,2.2-1.6,2.8-2.8l1.3-2.5c0.5-0.9,1.5-1.5,2.5-1.4c1.9-0.1,3.5,1.4,3.5,3.3c0,0.1,0,0.2,0,0.3
	c0,1.4-0.7,2.7-2,3.4L666.9,259.7z"
        />
        <path
            id="パス_127"
            class="st1"
            d="M666.9,269.8c1-0.6,1.7-1.7,1.7-2.8c0-1.5-1.2-2.6-2.6-2.6c0,0,0,0,0,0c-1.5,0-2.1,1.4-2.6,2.5
	l-1.4,2.7c-0.5,1-1.6,1.6-2.8,1.6c-2.4-0.2-4.2-2.2-4-4.6c0.1-1.8,1.3-3.3,3-3.8l0.3,0.8c-1.5,0.5-2.5,1.8-2.5,3.4
	c0,1.9,1.4,3.4,3.3,3.5c1.6,0,2.2-1.6,2.8-2.8l1.3-2.5c0.5-0.9,1.5-1.5,2.5-1.4c1.9-0.1,3.5,1.4,3.5,3.3c0,0.1,0,0.2,0,0.3
	c0,1.4-0.7,2.7-2,3.4L666.9,269.8z"
        />
        <path
            id="パス_128"
            class="st1"
            d="M666.3,290.2c1.4-1.2,2.2-2.9,2.2-4.7c-0.1-3.5-2.9-6.3-6.4-6.4c-1.6,0-3.2,0.7-4.3,1.9
	c-1.2,1.2-1.9,2.8-1.9,4.5c0,1.8,0.8,3.5,2.1,4.7h-1.2c-2.6-3-2.3-7.5,0.7-10.1s7.5-2.3,10.2,0.7c1.2,1.3,1.8,3.1,1.8,4.8
	c0,1.7-0.7,3.4-1.8,4.6L666.3,290.2L666.3,290.2z"
        />
        <path
            id="パス_129"
            class="st1"
            d="M659.8,294.2l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9L659.8,294.2z M667.4,297.3
	l-6.7-2.7v5.5L667.4,297.3z"
        />
        <path
            id="パス_130"
            class="st1"
            d="M654.7,317.3l12.5-11.7h-12v-0.9h14.2L657,316.4h12.2v0.9L654.7,317.3L654.7,317.3z"
        />
        <path
            id="パス_131"
            class="st1"
            d="M659.8,321.7l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9L659.8,321.7z M667.4,324.8
	l-6.7-2.7v5.5L667.4,324.8z"
        />
        <path id="パス_132" class="st1" d="M656,333.1v3.6h-0.8v-4.5h14v0.9H656z" />
        <path
            id="パス_133"
            class="st1"
            d="M249.9,218.2l12.7,5.2l-12.7,5.2l12,3.9v0.9l-14.4-4.7l12.9-5.3l-12.9-5.3l14.4-4.7v0.9
	L249.9,218.2z"
        />
        <path
            id="パス_134"
            class="st1"
            d="M261.1,236.1H256v5.6h-0.8v-5.6h-6.4v5.8h-0.8v-6.7h14v6.7h-0.8L261.1,236.1L261.1,236.1z"
        />
        <path
            id="パス_135"
            class="st1"
            d="M259.6,251c1-0.6,1.7-1.7,1.6-2.8c0-1.4-1.2-2.6-2.6-2.6c0,0,0,0,0,0c-1.5,0-2.1,1.4-2.6,2.5
	l-1.4,2.7c-0.6,1-1.6,1.6-2.8,1.6c-2.4-0.2-4.2-2.2-4.1-4.6c0.1-1.8,1.3-3.3,3-3.8l0.4,0.8c-1.5,0.4-2.5,1.8-2.5,3.4
	c0,1.9,1.4,3.4,3.3,3.4c1.6,0,2.2-1.6,2.8-2.8l1.3-2.5c0.5-0.9,1.5-1.5,2.5-1.4c1.9-0.1,3.5,1.4,3.5,3.3c0,0.1,0,0.2,0,0.3
	c0,1.4-0.7,2.7-2,3.4L259.6,251z"
        />
        <path
            id="パス_136"
            class="st1"
            d="M247.9,257.8v-0.9h13.1v-3.6h0.8v8.1h-0.8v-3.6L247.9,257.8z"
        />
        <path
            id="パス_137"
            class="st1"
            d="M259.1,279.8c1.4-1.2,2.2-2.9,2.2-4.7c-0.1-3.5-2.9-6.3-6.4-6.4c-1.6,0-3.2,0.7-4.3,1.9
	c-1.2,1.2-1.9,2.8-1.9,4.5c0.1,1.8,0.8,3.5,2.1,4.7h-1.2c-2.6-3-2.3-7.5,0.7-10.1s7.5-2.3,10.2,0.7c1.2,1.3,1.8,3.1,1.8,4.8
	c0,1.7-0.7,3.4-1.8,4.6L259.1,279.8L259.1,279.8z"
        />
        <path
            id="パス_138"
            class="st1"
            d="M252.5,283.8l-4.6-1.9V281l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9L252.5,283.8z M260.1,286.9
	l-6.7-2.7v5.5L260.1,286.9z"
        />
        <path
            id="パス_139"
            class="st1"
            d="M247.5,306.9l12.5-11.7h-12v-0.9h14.2L249.7,306h12.2v0.9H247.5z"
        />
        <path
            id="パス_140"
            class="st1"
            d="M252.5,311.3l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9L252.5,311.3L252.5,311.3z
	 M260.1,314.3l-6.7-2.7v5.5L260.1,314.3z"
        />
        <path id="パス_141" class="st1" d="M248.8,322.7v3.6h-0.8v-4.5h14v0.9H248.8z" />
        <path
            id="パス_142"
            class="st1"
            d="M979.7,299.6h-5.1v5.6h-0.8v-5.6h-6.4v5.8h-0.8v-6.7h14v6.7h-0.8L979.7,299.6z"
        />
        <path
            id="パス_143"
            class="st1"
            d="M971.2,309.5l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9V309.5z M978.8,312.6l-6.7-2.7v5.5
	L978.8,312.6z"
        />
        <path
            id="パス_144"
            class="st1"
            d="M978.3,326.5c1-0.6,1.7-1.7,1.7-2.8c0-1.5-1.2-2.6-2.6-2.6c0,0,0,0,0,0c-1.5,0-2.1,1.4-2.6,2.5
	l-1.4,2.7c-0.5,1-1.6,1.6-2.8,1.6c-2.4-0.2-4.2-2.2-4.1-4.6c0.1-1.8,1.3-3.3,3-3.9l0.3,0.8c-1.5,0.5-2.5,1.8-2.5,3.4
	c0,1.9,1.4,3.4,3.3,3.5c1.6,0,2.2-1.6,2.8-2.8l1.3-2.5c0.5-0.9,1.5-1.5,2.5-1.5c1.9-0.1,3.5,1.4,3.5,3.3c0,0.1,0,0.2,0,0.3
	c0,1.4-0.7,2.7-2,3.4L978.3,326.5z"
        />
        <path id="パス_145" class="st1" d="M966.6,333.2v-0.9h13.1v-3.6h0.8v8.1h-0.8v-3.6H966.6z" />
        <path
            id="パス_146"
            class="st1"
            d="M977.7,355.2c1.4-1.2,2.2-2.9,2.2-4.7c-0.1-3.5-2.9-6.3-6.4-6.4c-1.6,0-3.2,0.7-4.3,1.9
	c-1.2,1.2-1.9,2.8-1.9,4.5c0,1.8,0.8,3.5,2.1,4.7h-1.2c-2.6-3-2.3-7.5,0.7-10.1s7.5-2.3,10.2,0.7c1.2,1.3,1.8,3.1,1.8,4.8
	c0,1.7-0.7,3.4-1.8,4.6L977.7,355.2L977.7,355.2z"
        />
        <path
            id="パス_147"
            class="st1"
            d="M971.2,359.2l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9V359.2z M978.8,362.3l-6.7-2.7v5.5
	L978.8,362.3z"
        />
        <path
            id="パス_148"
            class="st1"
            d="M966.1,382.3l12.5-11.7h-12v-0.9h14.2l-12.4,11.7h12.2v0.9H966.1z"
        />
        <path
            id="パス_149"
            class="st1"
            d="M971.2,386.7l-4.6-1.9v-0.9l14.4,5.9l-14.4,5.8v-0.9l4.6-1.9V386.7z M978.8,389.8L972,387v5.5
	L978.8,389.8z"
        />
        <path id="パス_150" class="st1" d="M967.4,398.1v3.6h-0.8v-4.5h14v0.9H967.4z" />
        <path
            id="パス_151"
            class="st1"
            d="M867.2,239.5v-11.7h1.8v27.6h-1.8v-14.2h-16.8v14.2h-1.8v-27.6h1.8v11.7L867.2,239.5z"
        />
        <line id="線_1" class="st0" x1="731.9" y1="587" x2="556.1" y2="587" />
        <line id="線_2" class="st0" x1="12.7" y1="328.5" x2="17.6" y2="466.3" />
        <g id="グループ_61" transform="translate(-40 -185)">
            <g id="グループ_49" transform="translate(675.171 198.004)">
                <g>
                    <g id="グループ_48" transform="translate(0 -0.004)">
                        <path
                            id="パス_152"
                            class="st2"
                            d="M3.9,32.2H2.8V13h1.1L3.9,32.2L3.9,32.2z M6.1,0c2.1,0,3.7,1.7,3.7,3.8S8.2,7.5,6.1,7.5
					C4,7.5,2.3,5.8,2.3,3.8c0-1,0.4-2,1.1-2.7C4.1,0.4,5.1,0,6.1,0 M8,1.9c-1-1-2.7-1-3.8,0s-1,2.7,0,3.8s2.7,1,3.8,0
					c0.5-0.5,0.8-1.2,0.8-1.9C8.7,3,8.5,2.4,8,1.9 M2.5,8.4c-1.5,0-2.8,1.2-2.8,2.8V20h1.1v-8.8c0-0.9,0.8-1.7,1.7-1.7h7.2
					c0.9,0,1.7,0.8,1.7,1.7V20h1.1v-8.8c0-1.5-1.2-2.8-2.8-2.8H2.5z M9.3,32.2H8.2V13h1.1C9.3,13,9.3,32.2,9.3,32.2z"
                        />
                        <path
                            id="パス_153"
                            class="st2"
                            d="M23.5,0c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8c0-1,0.4-2,1.1-2.7
					C21.5,0.4,22.5,0,23.5,0 M19.8,23.8h2v8.4h-1.1v-7.4h-2.2l0.1-0.6l2-11.3l1,0.4L19.8,23.8z M25.2,23.8h2l-1.8-10.3l1-0.5l2,11.2
					l0.1,0.6h-2.2v7.3h-1.1L25.2,23.8L25.2,23.8z M21,8.4c-0.7,0-1.3,0.3-1.8,0.8c-0.5,0.6-0.9,1.2-1,1.9L16,19.3v0l1.1,0.2l2.1-8.1
					c0.1-0.5,0.4-1.1,0.8-1.5c0.3-0.3,0.6-0.4,1-0.4h5c0.4,0,0.8,0.1,1,0.4c0.4,0.4,0.6,0.9,0.8,1.5l2.2,8.2l0,0l1.1-0.2
					c0,0,0-0.1,0-0.1l-2.2-8.2c-0.2-0.7-0.5-1.4-1-1.9c-0.5-0.5-1.1-0.8-1.8-0.8H21z M25.4,1.9c-1-1-2.7-1-3.8,0s-1,2.7,0,3.8
					s2.7,1,3.8,0c0.5-0.5,0.8-1.2,0.8-1.9C26.2,3,25.9,2.4,25.4,1.9"
                        />
                    </g>
                </g>
            </g>
            <path
                id="パス_1361"
                class="st1"
                d="M684.8,190.1l5.2-4.9c0.3-0.3,0.8-0.3,1.2,0l5.2,4.9c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
		c-0.3,0.3-0.8,0.4-1.1,0.1c0,0,0,0,0-0.1l-4.6-4.4l-4.6,4.4c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0-0.1
		C684.5,191,684.5,190.5,684.8,190.1C684.8,190.2,684.8,190.1,684.8,190.1z"
            />
        </g>
        <g>
            <path class="st1" d="M870.4,157.5L858.7,145v12h-0.9v-14.2l11.7,12.4v-12.2h0.9V157.5z" />
            <path
                class="st1"
                d="M887.1,150.1c0,3.9-3.3,7.2-7.2,7.2c-3.9,0-7.2-3.2-7.2-7.2c0-3.9,3.3-7.2,7.2-7.2
		C883.8,142.9,887.1,146.1,887.1,150.1z M873.5,150.1c0,3.4,2.9,6.3,6.4,6.3c3.5,0,6.4-2.9,6.4-6.3c0-3.4-2.9-6.3-6.4-6.3
		C876.4,143.7,873.5,146.6,873.5,150.1z"
            />
            <path
                class="st1"
                d="M896.2,157.1h-1l-4.6-6.6h-0.4v6.6h-0.9v-14h1.7c1.1,0,2.2,0,3.2,0.7c1,0.7,1.4,1.8,1.4,3c0,2.4-1.7,3.7-4,3.6
		L896.2,157.1z M891.3,149.6c0.9,0,1.7,0,2.4-0.6c0.7-0.5,1-1.4,1-2.2c0-1-0.5-2.1-1.5-2.5c-0.7-0.4-1.7-0.3-2.5-0.3h-0.6v5.7H891.3
		z"
            />
            <path class="st1" d="M901.1,157.1h-0.9v-13.1h-3.6v-0.8h8.1v0.8h-3.6V157.1z" />
            <path
                class="st1"
                d="M915.7,149v-5.9h0.9v14h-0.9v-7.2h-8.5v7.2h-0.9v-14h0.9v5.9H915.7z"
            />
        </g>
        <g>
            <path
                class="st1"
                d="M586.8,421.7c-0.6-1-1.5-1.7-2.9-1.7c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.4,2.1,2.5,2.6l2.7,1.4
		c1.1,0.7,1.6,1.5,1.6,2.8c0,2.3-2.1,4.1-4.4,4.1c-2,0-3.5-1.2-4.2-3l0.8-0.4c0.6,1.5,1.7,2.5,3.4,2.5c1.9,0,3.5-1.5,3.5-3.3
		c0-1.6-1.6-2.3-2.8-2.9l-2.5-1.3c-0.9-0.6-1.5-1.4-1.5-2.5c0-2.1,1.5-3.6,3.6-3.6c1.4,0,2.8,0.8,3.4,2L586.8,421.7z"
            />
            <path
                class="st1"
                d="M604.3,426.4c0,4-3.4,7.2-7.3,7.2c-4,0-7.3-3.3-7.3-7.2s3.3-7.2,7.3-7.2C600.9,419.2,604.3,422.5,604.3,426.4z
		 M590.5,426.4c0,3.5,2.9,6.4,6.4,6.4c3.5,0,6.4-2.9,6.4-6.4c0-3.5-2.9-6.4-6.4-6.4C593.4,420,590.5,423,590.5,426.4z"
            />
            <path
                class="st1"
                d="M615,419.4h0.9v8.8c0,1.4,0,2.7-1,3.9c-1,1.1-2.4,1.6-3.8,1.6c-1.4,0-2.9-0.6-3.8-1.6c-1-1.2-1-2.4-1-3.9v-8.8
		h0.9v8.5c0,1.3,0,2.6,0.7,3.6c0.7,0.9,2.1,1.4,3.2,1.4c1.1,0,2.5-0.5,3.2-1.4c0.7-0.9,0.7-2.3,0.7-3.6V419.4z"
            />
            <path class="st1" d="M622,433.5h-0.9v-13.3h-3.7v-0.8h8.2v0.8H622V433.5z" />
            <path
                class="st1"
                d="M636.7,425.4v-6h0.9v14.1h-0.9v-7.3h-8.6v7.3h-0.9v-14.1h0.9v6H636.7z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'MapImage',
    props: {
        width: {
            type: [Number, String],
            default: 1345
        },
        height: {
            type: [Number, String],
            default: 588
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.st0 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.st1 {
    fill: #ffffff;
}
.st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
}
</style>
