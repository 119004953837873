import * as robotService from '@/services/robotService';
import apiConfig from '@/config/apiConfig';
import { useNotification } from '@/plugins/notification';

const useCallTheCar = dialog => {
    const { luobidePoc: pocApiConfig } = apiConfig;
    const { params = {} } = pocApiConfig;

    const notification = useNotification();

    const confirmCallTheCar = async pointUuid => {
        const { data = [] } = (await robotService.callTask(pointUuid, params.deviceSn)) || {};
        // 提示指令发送成功

        notification.success('操作成功！');
        dialog.valueOf = false;
    };

    return {
        confirmCallTheCar,
    };
};

export default useCallTheCar;
