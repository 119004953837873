<!-- plugins/notification/NotificationComponent.vue -->
<template>
    <transition name="notification-fade">
        <div v-if="visible" :class="['notification', `notification-${type}`]" role="alert">
            <div class="notification-content">
                <span class="message">{{ message }}</span>
                <button class="close-btn" @click="close">×</button>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    message: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'success',
    },
    duration: {
        type: Number,
        default: 3000,
    },
    onClose: {
        type: Function,
        required: true,
    },
});

const visible = ref(false);

const close = () => {
    visible.value = false;
};

onMounted(() => {
    visible.value = true;
    if (props.duration > 0) {
        setTimeout(() => {
            close();
        }, props.duration);
    }
});
</script>

<style scoped>
.notification {
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    border-radius: 4px;
    z-index: 9999;
    min-width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.notification-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-success {
    background-color: #f0f9eb;
    border: 1px solid #e1f3d8;
    color: #67c23a;
}

.notification-error {
    background-color: #fef0f0;
    border: 1px solid #fde2e2;
    color: #f56c6c;
}

.notification-warning {
    background-color: #fdf6ec;
    border: 1px solid #faecd8;
    color: #e6a23c;
}

.notification-info {
    background-color: #f4f4f5;
    border: 1px solid #e9e9eb;
    color: #909399;
}

.close-btn {
    margin-left: 16px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: inherit;
    opacity: 0.7;
}

.close-btn:hover {
    opacity: 1;
}

.notification-fade-enter-active,
.notification-fade-leave-active {
    transition: all 0.3s ease;
}

.notification-fade-enter-from,
.notification-fade-leave-to {
    opacity: 0;
    transform: translate(-50%, -100%);
}
</style>
