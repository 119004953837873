<template>
    <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        id="レイヤー_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20.57 16.01"
        style="enable-background: new 0 0 20.57 16.01"
        :width="width"
        :height="height"
        :fill="color"
        xml:space="preserve"
    >
        <g>
            <g>
                <g id="グループ_7">
                    <path
                        id="パス_3"
                        class="st0"
                        d="M0,2.93v12.5c0,0.32,0.26,0.58,0.58,0.57c0.07,0,0.14-0.01,0.2-0.04l4.93-2.25V0L0.36,2.43
                    C0.14,2.51,0,2.71,0,2.93 M19.78,0.04l-4.93,2.25V16l5.36-2.43c0.21-0.08,0.35-0.27,0.36-0.5V0.57C20.57,0.26,20.3,0,19.99,0
                    C19.92,0,19.85,0.01,19.78,0.04 M6.86,13.71L13.71,16V2.29L6.86,0V13.71z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'FloorGuideIcon',
    props: {
        width: {
            type: [Number, String],
            default: 20
        },
        height: {
            type: [Number, String],
            default: 16
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
