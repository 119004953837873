// import robotInsideBg from '@/assets/img/map.jpg';
import robotInsideBg from '@/assets/img/map-content.jpg';
// import robotInsideBg from '@/assets/img/robotInsideBg.png';

const config = {
    // 洛必得
    2: {
        mapImgUrlPath: robotInsideBg, // 1px = 100mm
        unitConversionToCmFn: value => value * 1, // 计算cm对应多少px
        cmToPxFn: value => value / 1,
        clacOffsetFn: ([x, y]) => [x - 0, y - 0],
        originOffset: [0, 0],
        robot: {
            '3e12edf1a11e3755b3d9d64e4a4b2d7a': {
                originOffset: [25, 44]
            }
        },
        initRoute: 0,
        roboticId: '3e12edf1a11e3755b3d9d64e4a4b2d7a',
        statusDesc: {
            true: '在线',
            false: '离线'
        },
        taskDescMap: {
            ABNORMAL: '异常状态',
            CALL: '召唤任务',
            CHARGE: '正在充电',
            CRUISE: '巡游展播',
            DELIVER: '客房送物',
            DELIVER_MEAL: '餐厅送餐',
            DELIVER_WAITING: '空闲待命',
            DISINFECT: '消杀防疫',
            DOCK: '正在入港',
            DRAINAGE: '消杀排水',
            GUIDE: '问路领路',
            IDLE: '空闲待命',
            IDLING: '空闲待命',
            RECEPTION: '迎宾接待',
            SETTING: '设置⻚面',
            TAKE_PHOTO: '智能拍照',
            TAKE_SEND: '帮取帮送',
            TO_CHARGE: '去充电中',
            TO_KITCHEN: '去候餐点',
            TROUBLE: '电梯急停',
            UNDOCK: '正在出港',
            WEDDING: '婚宴签到'
        },
        robotMsg: {
            robotImg: 'https://clound.irobint.com/static/robint/img_xiaoluo_zh01.png',
            robotName: '洛必得W1配送机器人',
            project: 'TISI上海社内配送'
        },
        points: [
            {
                title: '站点C',
                left: '30%',
                top: '70%'
            },
            {
                title: '站点A',
                left: '15%',
                top: '35%'
            },
            {
                title: '站点B',
                left: '75%',
                top: '30%'
            }
        ],
        mapInfo: {
            //
            // minLon: 106.66701222780691,
            // maxLon: 106.67154824986548,
            // minLat: 26.747229028405016,
            // maxLat: 26.751477084311528,

            // 数值越大越往左
            minLon: 106.6666,
            // 数值越大越往上
            maxLat: 26.75221
        }
    }
};
export default config;
