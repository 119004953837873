<script setup>
import AreaTypeEnum from '@/views/DealWarnVideoLive/enums/AreaTypeEnum';

import { toRefs } from '@vueuse/core';
import CallTheCarModal from '@/views/DealWarnVideoLive/components/CallTheCarModal/index.vue';
import { ref } from 'vue';

const props = defineProps({
    robotInfo: {
        type: Object,
        default: () => ({}),
    },
    currentWarnData: {
        type: Object,
        default: () => ({}),
    },
});

const { robotInfo, currentWarnData } = toRefs(props);

const isPublicArea = currentWarnData.roomRegionType === AreaTypeEnum.PUBLIC;

const callTheCarModalRef = ref(null);

const emits = defineEmits(['openVideoModal']);

const openVideoModal = () => {
    console.log('currentWarnData', currentWarnData);
    emits('openVideoModal', currentWarnData.value);
};

const SendTaskModal = () => {
    callTheCarModalRef.value?.showModal();
};

const onLineStatusDesc = {
    0: '离线',
    1: '在线',
};
</script>

<template>
    <div>
        <v-card class="d-flex flex-column p-4" color="rgba(0,0,0,0)" flat>
            <v-row class="pa-3 pb-0 pt-3" no-gutters>
                <v-col class="pr-3" cols="6">
                    <v-img
                        v-if="isPublicArea"
                        alt="Task icon"
                        class="max-h-[200px]"
                        contain
                        src="@/assets/img/camera.png"
                    />
                    <v-img
                        v-else
                        alt="Task icon"
                        class="max-h-[200px]"
                        contain
                        src="@/assets/img/robot-lbd.jpg"
                    />
                    <div class="mt-4">设备名称: {{ robotInfo.robotName }}</div>

                    <div>
                        当前状态: {{ isPublicArea ? '在线' : onLineStatusDesc[robotInfo.online] }}
                    </div>
                </v-col>
                <v-col align-self="center" cols="6">
                    <div class="mb-4">
                        <v-btn
                            :rounded="0"
                            class="text-[#444444] font-light ms-4 rounded-0"
                            color="#424242"
                            style="color: #C0C0C0;"
                            variant="flat"
                            @click="openVideoModal"
                        >
                            调取监控
                        </v-btn>
                    </div>

                    <template v-if="isPublicArea">
                        <v-btn
                            :rounded="0"
                            class="text-[#444444] font-light ms-4 rounded-0"
                            color="#ca5858"
                            style="color: #ffffff;"
                            variant="flat"
                        >
                            视频回放
                        </v-btn>
                    </template>

                    <template v-else>
                        <v-btn
                            :rounded="0"
                            class="text-[#444444] font-light ms-4 rounded-0"
                            color="#ca5858"
                            style="color: #ffffff;"
                            variant="flat"
                            @click="SendTaskModal"
                        >
                            任务调度
                        </v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-card>

        <CallTheCarModal ref="callTheCarModalRef" />
    </div>
</template>

<style scoped></style>
