<template>
    <v-card max-height="7.5vh" class="pb-2 ma-0 d-flex flex-column align-center justify-center">
        <v-card-title class="subtitle-1 pa-1 ma-0">バッテリー</v-card-title>
        <div class="batteryContainer">
            <div class="batteryOuter">
                <div id="batteryLevel" :style="getStyle(value)"></div>
            </div>
            <div class="ml-1 align-center justify-end">{{ value }} %</div>
        </div>
    </v-card>
</template>

<script>
import displayConfig from '@/config/DisplayConfig';

export default {
    name: 'BatteryInfo',

    components: {},
    props: {
        value: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getStyle(value) {
            let color = displayConfig.color.battery.high;
            if (value < 21) {
                color = displayConfig.color.battery.low;
            } else if (value < 41) {
                color = displayConfig.color.battery.middle;
            }
            return {
                width: `${value}%`,
                'background-color': color
            };
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.batteryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.batteryOuter {
    border-radius: 8px;
    background-color: #444;
    width: 200px;
    height: 16px;
}

#batteryLevel {
    border-radius: 8px;
    height: 16px;
}
</style>
