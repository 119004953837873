import axiosWithoutAuth from '@/lib/axiosWithoutAuth';
import apiConfig from '@/config/apiConfig';

const { poc: pocApiConfig } = apiConfig;
const { host } = pocApiConfig;

export const getList = async params => {
    return await axiosWithoutAuth.get(`${host}/alarm/msg`, params);
};

// 处理一个报警
export const handleOne = async alertRealTimeId => {
    return await axiosWithoutAuth.postWithBody(`${host}/alarm/msg`, {
        alertRealTimeId,
    });
};

// 处理一个报警
export const getOne = async alertId => {
    return await axiosWithoutAuth.get(`${host}/alarm/status?alertId=${alertId}`);
};
