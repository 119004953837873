<template>
    <v-container>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">下发任务</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-select
                            v-model="startStation"
                            :items="stations"
                            item-title="text"
                            item-value="value"
                            label="起始站点"
                        ></v-select>
                        <v-select
                            v-model="viaStation"
                            :items="stations"
                            item-title="text"
                            item-value="value"
                            label="途径站点"
                        ></v-select>
                        <v-select
                            v-model="endStation"
                            :items="stations"
                            item-title="text"
                            item-value="value"
                            label="结束站点"
                        ></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :rounded="0"
                        class="text-[#C0C0C0] font-light ms-4 rounded-0"
                        color="#424242"
                        variant="flat"
                        @click="dialog = false"
                        >取消
                    </v-btn>
                    <v-btn
                        :rounded="0"
                        class="text-[#C0C0C0] font-light ms-4 rounded-0"
                        color="#4558C8"
                        variant="flat"
                        @click="confirmTask"
                        >确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';
import stations from '../const/stations';

export default {
    data() {
        return {
            dialog: false,
            startStation: null,
            viaStation: null,
            endStation: null,
            stations
        };
    },
    methods: {
        confirmTask: async function() {
            // 在这里处理确认任务的逻辑

            // 判断相邻站点是否相同
            if (this.startStation === this.viaStation || this.viaStation === this.endStation) {
                alert('相邻站点不能相同');
                return;
            }

            console.log('任务已确认:', this.startStation, this.viaStation, this.endStation);

            // ID 数组
            const ids = [this.startStation, this.viaStation, this.endStation];

            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params } = pocApiConfig;
            const endpoint = `${host}${apis.sendTask.path}`;
            const response = await axiosWithoutAuth.postWithBody(endpoint, {
                ...params,
                stationIdList: ids
            });

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            }

            console.log('任务ID:', ids);
            this.dialog = false;
        },

        showModal() {
            this.dialog = true;
        }
    }
};
</script>

<style scoped>
.headline {
    font-weight: bold;
}
</style>
