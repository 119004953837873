import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView';
import BaseAndRobotOperation from '@/views/BaseAndRobotOperation/index.vue';
import Demo from '@/views/Demo/index.vue';
import YSDemo from '@/views/YSDemo/index.vue';
import DealWarnVideoLive from '@/views/DealWarnVideoLive/index.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
    },
    {
        path: '/BaseAndRobotOperation',
        name: 'BaseAndRobotOperation',
        component: BaseAndRobotOperation,
    },
    {
        path: '/Demo',
        name: 'Demo',
        component: Demo,
    },
    {
        path: '/YSDemo',
        name: 'YSDemo',
        component: YSDemo,
    },
    {
        path: '/DealWarnVideoLive',
        name: 'DealWarnVideoLive',
        component: DealWarnVideoLive,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
