<template>
    <v-app>
        <HeaderNoAuth />
        <v-main class="scrollable-main">
            <router-view />
            <!--            <NotificationProvider />-->
        </v-main>
    </v-app>
</template>

<script>
import HeaderNoAuth from '@/components/HeaderNoAuth';
// import NotificationProvider from '@/components/NotificationProvider.vue'; // import "./style.css"

// import "./style.css"
export default {
    name: 'App',
    components: {
        HeaderNoAuth,
        // NotificationProvider,
    },
    data: () => ({
        snackbar: false,
        message: '',
        timeout: 3000,
    }),
    computed: {},

    watch: {},
    methods: {},
};
</script>

<style>
#app {
    height: 100vh;
    overflow: hidden;
}

.scrollable-main {
    flex: 1;
    overflow-y: auto;
}
</style>
