const config = {
    host: 'https://api.roboticbase.dx-cluster-tisi01.tisi.com.cn',
    // 情報を取得したいエンティティのID
    entities: [
        'urn:ngsi-ld:toyosureception:0000',
        'urn:ngsi-ld:frutera:0000',
        'urn:ngsi-ld:temi:0000',
        'urn:ngsi-ld:temi:0001',
    ],
    path: {
        // orion上のエンティティにアクセスするためのAPI
        orion: '/orion/v2/entities',
        // 注文受付ブリッジエンティティのAPI
        receptionStatus: '/toyosuReception/receptionStatus',
    },
    pollingInterval: 1000,
    toyosuReception: {
        host: process.env.VUE_APP_YUKARI_API_ENDPOINT,
        path: {
            receptionStatus: '/toyosuReception/receptionStatus',
        },
        pollingInterval: 5000,
    },
    poc: {
        host: 'https://api.roboticbase.dx-cluster-tisi01.tisi.com.cn/toyosuReception/tpiApi/v1',
        params: {
            deviceSn: 'RoboBus00014',
        },
        apis: {
            getStationStatus: {
                // method: 'GET',
                path: '/getStationInfo',
                pollingInterval: 1000,
            },
            getRobotStatus: {
                // method: 'GET',
                path: '/getRobotInfo',
                pollingInterval: 1000,
            },
            sendTask: {
                // method: 'POST',
                path: '/addTask',
            },
            sendDeviceCmd: {
                path: '/sendDeviceCmd',
                // method: 'POST',
            },
        },
    },
    luobidePoc: {
        host: 'https://api.roboticbase.dx-cluster-tisi01.tisi.com.cn/toyosuReception/tpiApi/v1',
        params: {
            deviceSn: 'lbdDelRobot3e122d7a',
        },
        apis: {
            getStationStatus: {
                // method: 'GET',
                path: '/getStationInfo',
                pollingInterval: 1000,
            },
            getRobotStatus: {
                // method: 'GET',
                path: '/getRobotInfo',
                pollingInterval: 1000,
            },
            sendTask: {
                // method: 'POST',
                path: '/addTask',
            },
            sendDeviceCmd: {
                path: '/sendDeviceCmd',
                // method: 'POST',
            },
        },
    },
    // fiware-serviceヘッダ
    fiwareService: 'roboticbase',
    // fiware-servicepathヘッダ
    fiwareServicepath: '/',
};

module.exports = config;
