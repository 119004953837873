const usePointPostionPie = async (config, canvasRef) => {
    //  处理单位换算

    // const handlePointUnit = point => {
    //     if (!Array.isArray(point)) {
    //         console.error('Invalid point data:', point);
    //         return null; // 或者返回一个默认值
    //     }
    //     const [x, y] = point;
    //     // 继续原有的逻辑
    //     return [config.unitConversionToCmFn(x), config.unitConversionToCmFn(y)];
    // };

    // 处理等比缩放
    // const handlePointScale = ([x, y]) => [config.cmToPxFn(x), config.cmToPxFn(y)];

    // 处理初始位置偏移量
    // const handlePointOffset = config.clacOffsetFn;

    // 将经纬度转换为画布坐标的函数
    const convertToCanvasCoordinates = (lat, lon) => {
        // 这里需要根据您的地图和经纬度范围进行调整

        // let x_ratio =
        //     (lon - config.mapInfo.minLon) / (config.mapInfo.maxLon - config.mapInfo.minLon);
        // let y_ratio =
        //     (lat - config.mapInfo.minLat) / (config.mapInfo.maxLat - config.mapInfo.minLat);
        // //
        // const x = x_ratio * canvasRef.value.width;
        // const y = (1 - y_ratio) * canvasRef.value.height;

        const x_ratio = (lon - config.mapInfo.minLon) * 172;
        const y_ratio = (config.mapInfo.maxLat - lat) * 195;
        //
        const x = x_ratio * canvasRef.value.width;
        const y = y_ratio * canvasRef.value.height;

        return [x, y];
    };

    // 统一处理点的转换
    const handlePoint = targetPoint => {
        console.log('handlePointUnit point targetPoint', targetPoint);

        const { lng, lat } = targetPoint;

        console.log('handlePointUnit point lng, lat', lng, lat);
        const [x, y] = convertToCanvasCoordinates(lat, lng);

        console.log('handlePointUnit point x, y', x, y);
        // 处理初始位置偏移量
        // let point = handlePointOffset?.([parseFloat(x), parseFloat(y)]);

        // console.log('handlePointUnit point 处理初始位置偏移量', point);

        // 处理单位换算
        // point = handlePointUnit(point);

        // console.log('handlePointUnit point 处理单位换算', point);

        // 处理等比缩放 转换为px
        // point = handlePointScale(point);

        return [x, y];
    };

    return {
        handlePoint
    };
};

export default usePointPostionPie;
