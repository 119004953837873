import colors from 'vuetify/lib/util/colors';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';
import { md3 } from 'vuetify/blueprints';
import RobotIcon from '@/assets/icon/robot.svg';

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        dark: true,
        colors: {
          primary: colors.indigo.lighten2,
          secondary: colors.deepOrange.lighten2,
          accent: colors.yellow.lighten2,
          error: colors.red.lighten1,
          success: colors.green.lighten1
        }
      }
    }
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      robot: {
        component: RobotIcon
      }
    }
  },
  blueprint: md3
});

