<script>
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

const STATION_STATUS = {
    WAITING: 0,
    DRIVING: 2,
    COMPLETED: 3
};

const STATION_STATUS_TEXT = {
    [STATION_STATUS.WAITING]: '等待中',
    [STATION_STATUS.DRIVING]: '行驶中',
    [STATION_STATUS.COMPLETED]: '完成'
};

export default {
    data() {
        return {
            stationRoute: [],
            STATION_STATUS_TEXT,
            STATION_STATUS,
            STATION_NAME: {
                22084: '站点A',
                23910: '站点B',
                23930: '站点C'
            }
        };
    },
    computed: {
        carPosition() {
            if (this.stationRoute.length === 0) return 0;

            const drivingIndex = this.stationRoute.findIndex(
                station => station.status === STATION_STATUS.DRIVING
            );

            console.log('drivingIndex:', drivingIndex);
            if (drivingIndex === -1) return 0;

            // 计算车辆应该在的百分比位置
            const totalStations = this.stationRoute.length;
            const stepPercentage = 100 / (totalStations - 1) - 15;
            return drivingIndex * stepPercentage;
        }
    },
    created() {
        this.loadData();
    },
    beforeUnmount() {
        clearTimeout(this.l);
    },
    methods: {
        loadData: async function() {
            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params } = pocApiConfig;
            const endpoint = `${host}${apis.getStationStatus.path}?deviceSn=${params.deviceSn}`;
            const response = await axiosWithoutAuth.get(endpoint);

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            } else {
                const { data } = response;

                console.log('RouteBar data:', data);
                // 结构 [{stationName, status}]
                // 站点状态code-1:等待中，2:行驶中，3:完成
                this.stationRoute = data.data;
            }
            this.timeoutId = setTimeout(this.loadData, apis.getStationStatus.pollingInterval);
        }
    }
};
</script>
<template>
    <div class="relative my-5">
        <template>
            <v-stepper :elevation="0" alt-labels class="custom-stepper bg-transparent" flat>
                <v-stepper-header class="custom-stepper-header bg-transparent" flat>
                    <template v-for="(station, index) in stationRoute" :key="`step-${index}`">
                        <v-stepper-item
                            :complete="station.status === STATION_STATUS.COMPLETED"
                            :value="index + 1"
                            color="green"
                        >
                            <template #title>
                                <div
                                    :class="{
                                        'text-green-lighten-1':
                                            station.status === STATION_STATUS.COMPLETED,
                                    }"
                                    class="d-flex flex-column gap-3 align-center"
                                >
                                    <span class="mb-3 d-block">
                                        {{ STATION_NAME[station.stationId] }}
                                    </span>
                                    <span class="custom-stepper-title text-caption text-center">
                                        {{ STATION_STATUS_TEXT[station.status] || '等待中' }}
                                    </span>
                                </div>
                            </template>
                        </v-stepper-item>

                        <v-divider v-if="index < stationRoute.length - 1" color="green" />
                    </template>
                </v-stepper-header>
            </v-stepper>
        </template>

        <!--                 车辆图标-->
        <div
            v-if="stationRoute.length > 0 && carPosition"
            :style="{ left: `${carPosition}%` }"
            class="absolute top-0"
        >
            <v-img src="@/assets/img/drive.png" width="40"></v-img>
        </div>
    </div>
</template>

<style scoped>
.custom-stepper {
    background: none;
}
</style>
