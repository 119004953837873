<template>
    <!-- 配達状況コンポーネント -->
    <v-card color="rgba(255,255,255,.1)" class="max-height">
        <v-card-subtitle class="d-flex">
            <v-icon color="grey" left>mdi-human-queue</v-icon>
            <div class="my-auto">配達状況</div>
        </v-card-subtitle>
        <v-divider />
        <v-card-text v-if="!outOfService" class="d-flex flex-column justify-center align-center">
            <p style="font-weight: 400; line-height: 2rem">
                現在の配達待ち数
            </p>
            <p class="pt-3" style="font-size: 5rem !important; font-weight: 300; line-height: 5rem">
                {{ reception?.queue_num?.value }}
            </p>
        </v-card-text>
        <v-card-text v-else class="d-flex flex-column justify-center align-center">
            <p class="pt-4" style="font-weight: 400; line-height: 2rem">
                配達サービス停止中
            </p>
            <p
                class="pt-1"
                style="font-size: 1.25rem !important; font-weight: 500; line-height: 2rem"
            >
                out of service
            </p>
            <p class="pt-1" style="font-size: 0.875rem; font-weight: 400; line-height: 1.375rem">
                {{ outOfServiceReason }}
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

export default {
    name: 'WaitingDelivery',
    props: {
        reception: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timeoutId: '',
            outOfService: false,
            outOfServiceReason: ''
        };
    },
    created() {
        this.getReceptionStatus();
    },
    beforeUnmount() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        getReceptionStatus: async function() {
            const endpoint = `${apiConfig.toyosuReception.host}${apiConfig.toyosuReception.path.receptionStatus}`;
            const response = await axiosWithoutAuth.get(endpoint);

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            } else {
                if (response.data.out_of_service) {
                    this.outOfService = true;
                    this.outOfServiceReason = response.data.reason;
                } else {
                    this.outOfService = false;
                    this.outOfServiceReason = '';
                }
            }
            this.timeoutId = setTimeout(
                this.getReceptionStatus,
                apiConfig.toyosuReception.pollingInterval
            );
        }
    }
};
</script>

<style scoped>
.max-height {
    height: 100%;
}
</style>
