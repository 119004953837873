import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hello-ezuikit-js" }

import EZUIKit from 'ezuikit-js';
import { onMounted } from 'vue';

import { useAuthStore } from '@/store/auth';

interface IPlayer {
    play: Function;
    stop: Function;
    getOSDTime: Function;
    capturePicture: Function;
    openSound: Function;
    closeSound: Function;
    startSave: Function;
    stopSave: Function;
    startTalk: Function;
    stopTalk: Function;
    fullScreen: Function;
    destroy: Function;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const auth = useAuthStore();

let player: IPlayer;

const play = () => {
    const playPromise = player.play();
    playPromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const stop = () => {
    const stopPromise = player.stop();
    stopPromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const getOSDTime = () => {
    const getOSDTimePromise = player.getOSDTime();
    getOSDTimePromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const capturePicture = () => {
    const capturePicturePromise = player.capturePicture(`${new Date().getTime()}`);
    capturePicturePromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const openSound = () => {
    const openSoundPromise = player.openSound();
    openSoundPromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const closeSound = () => {
    const openSoundPromise = player.closeSound();
    openSoundPromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const startSave = () => {
    const startSavePromise = player.startSave(`${new Date().getTime()}`);
    startSavePromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const stopSave = () => {
    const stopSavePromise = player.stopSave();
    stopSavePromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
};

const ezopenStartTalk = () => {
    player.startTalk();
};

const ezopenStopTalk = () => {
    player.stopTalk();
};

const fullScreen = () => {
    player.fullScreen();
};

const destroy = () => {
    const destroyPromise = player.destroy();
    destroyPromise.then((data: any) => {
        console.log('promise 获取 数据', data);
    });
    player = null!;
};

const init = () => {
    if (player) {
        destroy();
    }
    console.group('mounted 组件挂载完毕状态===============》');
    // fetch("https://open.ys7.com/jssdk/ezopen/demo/token")
    //   .then((response) => response.json())
    //   .then((res) => {
    //     var accessToken = res.data.accessToken;
    player = new EZUIKit.EZUIKitPlayer({
        id: 'video-container', // 视频容器ID
        accessToken: auth.YSToken,
        url: 'ezopen://FSYTVS@open.ys7.com/BE0152189/1.live',
        // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
        template: 'pcLive',
        plugin: ['talk'], // 加载插件，talk-对讲
        width: 600,
        height: 400,
        // language: "en", // zh | en
        handleError: (err: any) => {
            console.error('handleError', err);
        },
        staticPath: '/ezuikit_static', // 如果想使用本地静态资源，请复制根目录下ezuikit_static 到当前目录下， 然后设置该值
    });
    window.player = player;
    // });
};

onMounted(() => {
    init();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      id: "video-container",
      style: {"width":"600px","height":"400px"}
    }, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("button", { onClick: init }, "init"),
      _createElementVNode("button", { onClick: stop }, "stop"),
      _createElementVNode("button", { onClick: play }, "play"),
      _createElementVNode("button", { onClick: openSound }, "openSound"),
      _createElementVNode("button", { onClick: closeSound }, "closeSound"),
      _createElementVNode("button", { onClick: startSave }, "startSave"),
      _createElementVNode("button", { onClick: stopSave }, "stopSave"),
      _createElementVNode("button", { onClick: capturePicture }, "capturePicture"),
      _createElementVNode("button", { onClick: fullScreen }, "fullScreen"),
      _createElementVNode("button", { onClick: getOSDTime }, "getOSDTime"),
      _createElementVNode("button", { onClick: ezopenStartTalk }, "开始对讲"),
      _createElementVNode("button", { onClick: ezopenStopTalk }, "结束对讲"),
      _createElementVNode("button", { onClick: destroy }, "销毁")
    ])
  ]))
}
}

})