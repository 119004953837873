import { onMounted, ref, watch } from 'vue';
import * as robotService from '@/services/robotService';
import { useTimeoutFn } from '@/hooks/core/useTimeout';
import apiConfig from '@/config/apiConfig';

const useRobotInfoData = () => {
    const robotInfo = ref({
        robotName: ' ',
        statusName: ' ',
        nextStation: ' ',
        modeName: '',
        speed: 0,
        battery: 0,
        lat: 0,
        lng: 0,
        angle: 0,
    });

    const { luobidePoc: pocApiConfig } = apiConfig;
    const { params = {} } = pocApiConfig;

    const fetchRobotData = async () => {
        const { data = [] } = (await robotService.getRobotInfo(params.deviceSn)) || {};

        robotInfo.value = data.data;
    };

    const pollingInterval = 2000; // 间隔时间，以毫秒为单位

    // 在组件的 setup 函数中使用 useTimeoutFn
    const { readyRef, start } = useTimeoutFn(() => {
        fetchRobotData();
    }, pollingInterval);

    onMounted(async () => {
        await fetchRobotData();

        start();
    });

    // 监听 readyRef 的变化，当 readyRef 变为 true 时，重新开始轮询
    watch(
        readyRef,
        maturity => {
            if (maturity) {
                start();
            }
        },
        { immediate: true }
    );

    return {
        robotInfo,
    };
};

export default useRobotInfoData;
