<template>
    <v-dialog :value="dialog" max-width="1000px" persistent>
        <v-card>
            <v-card-title class="d-flex">
                <v-icon color="grey" left>mdi-image-multiple</v-icon>
                <div class="text-grey">差分を検知しました。</div>
            </v-card-title>
            <!--            <v-card-text class="d-flex align-center justify-center">-->
            <!--                <v-card flat>-->
            <!--                    <v-img src="@/assets/img/before.jpg" max-width="400px" class="ma-5" />-->
            <!--                    <div class="d-flex align-center justify-center grey&#45;&#45;text">-->
            <!--                        前回確認分-->
            <!--                    </div>-->
            <!--                </v-card>-->
            <!--                <v-card flat>-->
            <!--                    <v-img src="@/assets/img/after.jpg" max-width="400px" class="ma-5" />-->
            <!--                    <div class="d-flex align-center justify-center grey&#45;&#45;text">-->
            <!--                        今回確認分-->
            <!--                    </div>-->
            <!--                </v-card>-->
            <!--            </v-card-text>-->
            <v-card-action class="d-flex">
                <v-spacer />
                <v-btn outlined color="primary" class="mb-5" @click="closeDialog()">閉じる</v-btn>
                <v-spacer />
            </v-card-action>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'NotificationListItemDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
