<template>
    <v-app-bar flat color="bg-grey-darken-4" class="max-height">
        <v-toolbar-title class="d-flex">
            <!-- リーガル対応のためアイコンを非表示 -->
            <!-- <v-icon size="30" color="blue-grey lighten-5">mdi-hexagon-slice-4</v-icon> -->
            <div class="logoTitle my-auto" @click="clickLogo">RoboticBase</div>
        </v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    name: 'HeaderNoAuth',
    methods: {
        // ロゴクリック時の処理
        clickLogo() {
            this.$router.push('/BaseAndRobotOperation').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import 'https://fonts.googleapis.com/css?family=ABeeZee&display=swap';

.logoTitle {
    font-family: 'ABeeZee';
    font-size: 25px;
}
.max-height {
    max-height: 64px;
}
</style>
