<script>
import RouteBar from './components/RouteBar.vue';
import StatusCard from './components/StatusCard.vue';
import RouteMap from './components/RouteMap/index.vue';
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

export default {
    components: {
        RouteBar,
        StatusCard,
        RouteMap,
    },
    data() {
        return {
            timeoutId: '',
            robotName: ' ',
            statusName: ' ',
            nextStation: ' ',
            modeName: '',
            speed: 0,
            battery: 0,
            lat: 0,
            lng: 0,
            angle: 0,
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData: async function() {
            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params = {} } = pocApiConfig;

            const endpoint = `${host}${apis.getRobotStatus.path}?deviceSn=${params.deviceSn}`;
            const response = await axiosWithoutAuth.get(endpoint);

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            } else {
                const { data: res } = response;

                const { data } = res;
                // 结构 [{stationName, status}]
                // 站点状态code-1:等待中，2:行驶中，3:完成
                this.robotName = data.robotName;
                this.statusName = data.statusName;
                this.nextStation = data.nextStation;
                this.modeName = data.modeName;
                this.speed = data.speed;
                this.battery = data.battery;
                this.lat = data.lat;
                this.lng = data.lng;
                this.angle = data.angle;
            }
            this.timeoutId = setTimeout(this.loadData, apis.getStationStatus.pollingInterval);
        },
    },
};
</script>

<template>
    <div class="max-width">
        <v-row class="pa-3 pb-0" no-gutters>
            <v-col class="pr-3" cols="7">
                <v-card color="rgba(255,255,255,.1)" flat height="650px" max-height="650px">
                    <v-card-subtitle class="d-flex">
                        <v-img
                            alt="Task icon"
                            contain
                            height="20"
                            max-height="20"
                            max-width="20"
                            src="@/assets/icon/task.svg"
                            width="20"
                        />
                        <span class="ml-2">车辆任务一览</span>
                    </v-card-subtitle>

                    <v-divider />
                    <v-spacer />

                    <RouteMap :angle="angle" :lat="lat" :lng="lng" />

                    <RouteBar />
                </v-card>
            </v-col>
            <v-col cols="5">
                <StatusCard />
            </v-col>
        </v-row>
    </div>
</template>

<style scoped>
.custom-stepper {
    box-shadow: none !important;
    //background-color: #b02626;
    background: none;

    .custom-stepper-title {
        margin-top: 10px;
    }
}

.custom-stepper-header {
    box-shadow: none !important;
}
</style>
