<template>
    <v-card color="rgba(255,255,255,.1)" class="max-height">
        <v-card-subtitle class="d-flex">
            <RobotIcon />
            <div class="pl-2">RoboticBase導入のご用命</div>
        </v-card-subtitle>
        <v-divider />

        <v-container>
            <v-row dense>
                <v-col cols="8">
                    <v-row class="pa-3 pt-5 information">
                        <span>{{ getInformation() }}</span>
                    </v-row>
                </v-col>
                <!--                <v-col cols="4" class="pa-1 align-center justify-center">-->
                <!--                    <v-img contain class="my-auto qr" src="@/assets/img/roboticbase_qrlink.png" />-->
                <!--                </v-col>-->
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import RobotIcon from '@/components/icon/Robot';

import displayConfig from '@/config/DisplayConfig';

export default {
    name: 'Information',
    components: { RobotIcon },
    props: {},
    data() {
        return {};
    },
    methods: {
        getInformation() {
            return displayConfig.text.information;
        }
    }
};
</script>

<style scoped>
.max-height {
    height: 100%;
}
.information {
    white-space: pre-wrap;
    font: 18px;
    color: #9f9e9f;
}
.qr {
    height: 100%;
    display: flex;
    align-items: center;
}
</style>
