const useImageSize = (imagePath) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imagePath;

    img.onload = function() {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };

    img.onerror = function() {
      reject('Failed to load the image');
    };
  });
};

export default useImageSize;
