<template>
    <div class="max-width">
        <v-row no-gutters class="pa-3 pb-0">
            <v-col cols="9" class="pr-3">
                <v-row no-gutters>
                    <v-col cols="12">
                        <FloorMap class="middle-image" :robots="robotEntities" />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-3">
                    <v-col cols="4">
                        <NotificationList :robots="robotEntities" />
                    </v-col>
                    <v-col cols="4" class="pl-3">
                        <WaitingDelivery :reception="receptionEntity" />
                    </v-col>
                    <v-col cols="4" class="pl-3">
                        <Information />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3">
                <RobotStatus :robots="robotEntities" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FloorMap from '@/components/FloorMap';
import Information from '@/components/Information';
import NotificationList from '@/components/NotificationList';
import RobotStatus from '@/components/RobotStatus';
import WaitingDelivery from '@/components/WaitingDelivery';

import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

export default {
    name: 'DashboardView',
    components: {
        FloorMap,
        Information,
        NotificationList,
        RobotStatus,
        WaitingDelivery
    },
    data: () => ({
        robotEntities: [],
        timeoutId: '',
        receptionEntity: {}
    }),

    created() {
        this.getEntities();
    },
    beforeUnmount() {
        clearTimeout(this.timeoutId);
    },

    methods: {
        getEntities: async function() {
            const entitiesStr = apiConfig.entities.join(',');
            const endpoint = `${apiConfig.host}${apiConfig.path.orion}?id=${entitiesStr}`;
            const response = await axiosWithoutAuth.getFromFiware(endpoint);
            if ('errors' in response) {
                console.log('error occurred in getEntities calling', endpoint, response);
            } else {
                const receptionEntities = response.data.filter(
                    robot => robot.type === 'toyosureception'
                );
                if (receptionEntities.length !== 0) {
                    this.receptionEntity = receptionEntities[0];
                }
                this.robotEntities = response.data
                    .filter(robot => robot.type !== 'toyosureception')
                    .sort(function(a, b) {
                        if (a.name.value > b.name.value) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
            }
            this.timeoutId = setTimeout(this.getEntities, apiConfig.pollingInterval);
        }
    }
};
</script>

<style scoped>
.max-height {
    height: 100%;
}

.max-width {
    width: 100%;
}
</style>
