import { nextTick, reactive } from 'vue';

const $ = window.$;
let g_iWndIndex = 0;

export const data = reactive({
    ip: '172.16.88.46', // 你的ip
    port: '80',
    userName: 'admin', // 账号
    password: 'Tisi@2u8', // 密码
    img: '',
    // 新增回放相关配置
    playbackInfo: {
        iChannelID: 1, // 通道号
        szStartTime: '', // 开始时间
        szEndTime: '', // 结束时间
        iStreamType: 1, // 码流类型
        iSpeed: 1, // 回放速度
    },
});

// 初始化插件参数及插入插件
export const init = () => {
    window.WebVideoCtrl?.I_InitPlugin({
        bWndFull: true, // 是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
        iWndowType: 1,
        cbSelWnd: function(xmlDoc) {
            g_iWndIndex = parseInt(
                $(xmlDoc)
                    .find('SelectWnd')
                    .eq(0)
                    .text(),
                10
            );
        },
        cbDoubleClickWnd: function() {},
        cbEvent: (iEventType, iParam1) => {
            if (iEventType == 2) {
                // 回放正常结束
                console.log('窗口' + iParam1 + '回放结束！');
            } else if (iEventType == -1) {
                console.log('设备' + iParam1 + '网络错误！');
            }
        },
        cbInitPluginComplete: function() {
            window.WebVideoCtrl?.I_InsertOBJECTPlugin('divPlugin').then(
                () => {
                    // 检查插件是否最新
                    window.WebVideoCtrl?.I_CheckPluginVersion().then(bFlag => {
                        if (bFlag) {
                            alert('检测到新的插件版本，双击开发包目录里的HCWebSDKPlugin.exe升级！');
                        } else {
                            console.log('初始化成功');
                            nextTick(() => {
                                login();
                            });
                        }
                    });
                },
                () => {
                    alert(
                        '插件初始化失败，请确认是否已安装插件；如果未安装，请双击开发包目录里的HCWebSDKPlugin.exe安装！'
                    );
                }
            );
        },
    });
};
// 抓图
export const capturePicData = type => {
    const oWndInfo = window.WebVideoCtrl?.I_GetWindowStatus(g_iWndIndex);
    if (oWndInfo != null) {
        window.WebVideoCtrl?.I_CapturePicData().then(
            res => {
                data.img = 'data:image/jpeg;base64,' + res;
                console.log('抓图成功', data.img, type);
            },
            function() {}
        );
    }
};
// 销毁插件
export const destroyPlugin = () => {
    console.log('WebVideoCtrl', window.WebVideoCtrl);
    window.WebVideoCtrl?.I_Logout(`${data.ip}_${data.port}`).then(
        () => {
            console.log('退出成功');
            window.WebVideoCtrl?.I_DestroyPlugin();
        },
        e => {
            console.log('退出失败', e);
        }
    );
};

export const resize = () => {
    window.WebVideoCtrl?.I_Resize();
};
export const hidePlugin = () => {
    window.WebVideoCtrl?.I_HidPlugin();
};
//  登录
export const login = () => {
    window.WebVideoCtrl?.I_Login(data.ip, 1, data.port, data.userName, data.password, {
        timeout: 3000,
        success: function(xmlDoc) {
            console.log('登录成功', xmlDoc);
            getDevicePort(`${data.ip}_${data.port}`); // 获取端口

            startRealPlay(); // 开始预览
        },
        error: function(error) {
            console.log(error);
        },
    });
};

// 获取端口
export const getDevicePort = szDeviceIdentify => {
    if (!szDeviceIdentify) {
        return;
    }
    window.WebVideoCtrl?.I_GetDevicePort(szDeviceIdentify).then(
        oPort => {
            console.log('登录成功', oPort);
        },
        oError => {
            console.log('登录失败', oError.errorMsg);
        }
    );
};
// 开始预览
export const startRealPlay = () => {
    const oWndInfo = window.WebVideoCtrl?.I_GetWindowStatus(g_iWndIndex);
    const startRealPlay = function() {
        window.WebVideoCtrl?.I_StartRealPlay(`${data.ip}_${data.port}`, {
            iStreamType: 1,
            iChannelID: 1, // 播放通道
            bZeroChannel: false,
            success: function() {
                console.log(' 开始预览成功！');
            },
            error: function(oError) {
                console.log(' 开始预览失败！', oError.errorMsg);
            },
        });
    };

    if (oWndInfo != null) {
        // 已经在播放了，先停止
        window.WebVideoCtrl?.I_Stop({
            success: () => {
                startRealPlay();
            },
        });
    } else {
        startRealPlay();
    }
};
//  格式化时间
export const dateFormat = (oDate, fmt) => {
    const o = {
        'M+': oDate.getMonth() + 1, // 月份
        'd+': oDate.getDate(), // 日
        'h+': oDate.getHours(), // 小时
        'm+': oDate.getMinutes(), // 分
        's+': oDate.getSeconds(), // 秒
        'q+': Math.floor((oDate.getMonth() + 3) / 3), // 季度
        S: oDate.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (oDate.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            );
        }
    }
    return fmt;
};

// 开始回放
export const startPlayback = () => {
    const oWndInfo = window.WebVideoCtrl?.I_GetWindowStatus(g_iWndIndex);

    // 格式化开始和结束时间
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    data.playbackInfo.szStartTime = dateFormat(oneHourAgo, 'yyyy-MM-dd hh:mm:ss');
    data.playbackInfo.szEndTime = dateFormat(now, 'yyyy-MM-dd hh:mm:ss');

    const startPlayback = () => {
        window.WebVideoCtrl?.I_StartPlayback(`${data.ip}_${data.port}`, {
            iChannelID: data.playbackInfo.iChannelID,
            szStartTime: data.playbackInfo.szStartTime,
            szEndTime: data.playbackInfo.szEndTime,
            iStreamType: data.playbackInfo.iStreamType,
            success: () => {
                console.log('开始回放成功！');
            },
            error: oError => {
                console.log('开始回放失败！', oError.errorMsg);
            },
        });
    };

    if (oWndInfo != null) {
        // 如果正在播放，先停止
        window.WebVideoCtrl?.I_Stop({
            success: () => {
                startPlayback();
            },
        });
    } else {
        startPlayback();
    }
};

// 停止回放
export const stopPlayback = () => {
    window.WebVideoCtrl?.I_Stop({
        success: () => {
            console.log('停止回放成功！');
        },
        error: oError => {
            console.log('停止回放失败！', oError.errorMsg);
        },
    });
};

// 按时间回放
export const playbackByTime = (startTime, endTime) => {
    if (!startTime || !endTime) {
        console.log('请指定开始时间和结束时间！');
        return;
    }

    data.playbackInfo.szStartTime = dateFormat(new Date(startTime), 'yyyy-MM-dd hh:mm:ss');
    data.playbackInfo.szEndTime = dateFormat(new Date(endTime), 'yyyy-MM-dd hh:mm:ss');

    startPlayback();
};

// 暂停回放
export const pausePlayback = () => {
    window.WebVideoCtrl?.I_Pause({
        success: () => {
            console.log('暂停回放成功！');
        },
        error: oError => {
            console.log('暂停回放失败！', oError.errorMsg);
        },
    });
};

// 恢复回放
export const resumePlayback = () => {
    window.WebVideoCtrl?.I_Resume({
        success: () => {
            console.log('恢复回放成功！');
        },
        error: oError => {
            console.log('恢复回放失败！', oError.errorMsg);
        },
    });
};
