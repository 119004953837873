<template>
    <div id="divPlugin" class="plugin" style="min-height: 400px; "></div>
</template>
<script lang="js" setup>
import { destroyPlugin, hidePlugin, init } from './index';
import { onBeforeUnmount, onMounted } from 'vue';

onMounted(() => {
    setTimeout(() => {
        init();
    }, 400);
});

onBeforeUnmount(() => {
    hidePlugin();
    destroyPlugin();
});
</script>
<style lang="less" scoped></style>;
