<template>
    <v-card color="rgba(255,255,255,.1)" class="max-height">
        <v-card-subtitle class="d-flex">
            <RobotIcon />
            <div class="pl-2">ロボット</div>
        </v-card-subtitle>
        <v-divider />

        <v-card-text v-for="(item, index) in robots" :key="item.name.value">
            <v-card-title class="subtitle-2 pa-0 pb-1 ma-0" :style="getStyle(index)">
                <RobotIcon />
                <div class="ml-1 align-center justify-end">{{ item.name.value }}</div>
            </v-card-title>
            <v-container class="pa-0 ma-0">
                <v-row dense class="status">
                    <v-col cols="4" class="align-center justify-center">
                        <v-img
                            contain
                            class="mr-1 robot"
                            :src="require(`@/assets/robots/${item.type}.png`)"
                        />
                    </v-col>
                    <v-col cols="8">
                        <v-row>
                            <v-col cols="6" class="pa-1 ma-0">
                                <StatusInfo :type="item.type" :value="item.status.value" />
                            </v-col>
                            <v-col cols="6" class="pa-1 ma-0">
                                <CommandInfo :type="item.type" :value="item.status.value" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pa-1 ma-0">
                                <BatteryInfo :value="item.battery.value" />
                            </v-col>
                        </v-row>
                        <v-row class="pa-1 pt-5 ma-0 description">
                            {{ getDesc(item.type) }}
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import RobotIcon from '@/components/icon/Robot';
import BatteryInfo from '@/components/robot_status/Battery';
import CommandInfo from '@/components/robot_status/Command';
import StatusInfo from '@/components/robot_status/Status';

import displayConfig from '@/config/DisplayConfig';

export default {
    name: 'RobotStatus',

    components: { RobotIcon, BatteryInfo, StatusInfo, CommandInfo },
    props: {
        robots: {
            type: Array,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getStyle(index) {
            if (index < displayConfig.color.theme.length) {
                return { color: displayConfig.color.theme[index] };
            }
            return { color: displayConfig.color.theme[0] };
        },
        getDesc(type) {
            let message = '-';
            if (type in displayConfig.text.description) {
                message = displayConfig.text.description[type];
            }
            return message;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.max-height {
    height: 100%;
}
.status {
    max-height: 245px;
}
.robot {
    max-width: 90%;
    width: auto;
    height: 250px;
}
.description {
    color: #9f9e9f;
}
</style>
