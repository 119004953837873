// const AreaTypeEnum = {
//     PUBLIC: 'PUBLIC',
//     PRIVATE: 'PRIVATE',
// };
//
// export default AreaTypeEnum;

enum AreaTypeEnum {
    PUBLIC = '02',
    PRIVATE = '01',
}

export default AreaTypeEnum;
