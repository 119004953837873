<script setup>
import useWarnListData from './hooks/useWarnListData';
import dayjs from 'dayjs';

const emits = defineEmits(['openVideoModal']);

const { warnListData } = useWarnListData();
const openVideoModal = item => {
    emits('openVideoModal', item);
};
</script>

<template>
    <v-card class="d-flex flex-column p-4" color="rgba(0,0,0,0)" flat>
        <v-table density="compact" fixed-header height="250px">
            <thead>
                <tr>
                    <th class="text-left" width="100px">
                        告警类型
                    </th>
                    <th class="text-left" width="220px">
                        告警区域
                    </th>
                    <th class="text-left bg-red-600" width="200px">
                        告警时间
                    </th>
                    <th class="text-left" width="100px">
                        状态
                    </th>
                    <th class="text-left">
                        操作
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="item in warnListData" :key="item.name">
                    <td>{{ item.typeName }}</td>
                    <td>{{ item.roomName }}</td>
                    <td>{{ dayjs(item.alertTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td>{{ item.alarmProcessName }}</td>
                    <td>
                        <v-btn
                            v-if="item.alarmProcess === '01'"
                            rounded="0"
                            size="x-small"
                            variant="tonal"
                            @click="openVideoModal(item)"
                        >
                            处理
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-card>
</template>

<style scoped></style>
