import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import GunVideoContent from '@/views/DealWarnVideoLive/components/GunVideoContent/index.vue';
import YSVideoContent from '@/views/DealWarnVideoLive/components/YSVideoContent/index.vue';
import { RoomRegionTypeEnum } from '@/enums/RoomRegionTypeEnum';
import { ref } from 'vue';
import { handleOne } from '@/services/warnService';
import AlamProcessEnum from '@/views/DealWarnVideoLive/enums/AlamProcessEnum';

import { useNotification } from '@/plugins/notification';

import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['updateCurrentWarn'],
  setup(__props, { expose: __expose, emit: __emit }) {

const notification = useNotification();

const route = useRoute(); // 获取路由对象

const alertTimeId = route.query.alertTimeId; // 从路由中获取查询参数

const show = ref(false);
const alertTimeData = ref();

const emits = __emit;

const dealWithWarnVideo = async () => {
    await handleOne(alertTimeData.value.alertTimeId);
    notification.success('操作成功！');

    // 如果当前的报警ID是处理的报警ID 则更新状态
    if (alertTimeData.value.alertTimeId === alertTimeId) {
        emits('updateCurrentWarn', {
            ...alertTimeData,
            alarmProcess: AlamProcessEnum.PROCESS,
        });
    }

    closeModal();
};

// 外部可以打开弹窗 内部可以关闭弹窗
const openModal = item => {
    alertTimeData.value = item;
    show.value = true;
};

const closeModal = () => {
    show.value = false;
};

__expose({ openModal }); // 暴露方法给父组件

return (_ctx: any,_cache: any) => {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: show.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((show).value = $event)),
    width: "600px"
  }, {
    default: _withCtx(() => [
      (show.value)
        ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("监控视频")
                    ]), undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_toolbar_items, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        icon: "mdi-close",
                        onClick: closeModal
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              (alertTimeData.value.roomRegionType === _unref(RoomRegionTypeEnum).PRIVATE)
                ? (_openBlock(), _createBlock(YSVideoContent, { key: 0 }))
                : (_openBlock(), _createBlock(GunVideoContent, { key: 1 })),
              (alertTimeData.value.alarmProcess === _unref(AlamProcessEnum).UNPROCESS)
                ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        rounded: 0,
                        class: "text-[#C0C0C0] font-light ms-4 rounded-0",
                        color: "#4558C8",
                        variant: "flat",
                        onClick: dealWithWarnVideo
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" 处理 ")
                        ]), undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["modelValue"]))
}
}

})