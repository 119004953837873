<template>
    <v-card color="rgba(255,255,255,.1)" class="max-height">
        <v-card-subtitle class="d-flex">
            <v-icon color="grey" left>mdi-message-bulleted</v-icon>
            <div class="my-auto">{{ title }}</div>
        </v-card-subtitle>
        <v-divider />

        <v-card-text>
            <div class="d-flex flex-column-reverse">
                <div
                    v-for="(notification, index) in notificationList.slice(
                        getSlicedCount(notificationList, displayNum)
                    )"
                    :key="index"
                >
                    <NotificationListItem
                        :type="notification.type"
                        :robot-name="notification.robotName"
                        :message="notification.message"
                        :date="notification.date"
                    />
                </div>
            </div>

            <!-- 通知が0件の場合、表示する -->
            <div
                v-if="notificationList.length === 0"
                class="d-flex flex-column align-center justify-center"
                height="100%"
            >
                <div class="d-flex flex-column">
                    <div class="text-grey">通知が0件です。</div>
                </div>
            </div>

            <!-- 表示を省略している部分があることを伝えるアイコンを表示 -->
            <div v-if="notificationList.length - displayNum > 0" class="d-flex">
                <v-spacer />
                <v-icon size="30">mdi-dots-vertical</v-icon>
                <v-spacer />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import NotificationListItem from '@/components/NotificationListItem';
import dispalyConfig from '@/config/DisplayConfig';

export default {
    name: 'NotificationList',
    components: { NotificationListItem },

    props: {
        robots: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        title: '通知',
        notificationList: []
    }),

    computed: {
        displayNum() {
            return dispalyConfig.alertCount;
        }
    },
    watch: {
        robots(newVal, oldVal) {
            // ロボットのエンティティを取り出し、通知欄に情報を追加する
            //   robotsは常にソートされて送られてくるため、newVal[0]とoldVal[0]は同じロボットを指す
            //   name属性と、command_status属性の両方を持つ場合のみ、状態を確認し、通知を生成する
            newVal.forEach((element, index) => {
                if (!('name' in element) || !('command_status' in element)) {
                    return;
                }
                const robotName = element.name.value;
                const newRobotState = element.command_status.value;
                const oldRobotState = oldVal[index].command_status.value;
                const type = element.type;
                switch (type) {
                    case 'frutera':
                        switch (newRobotState) {
                            case 'init':
                                if (oldRobotState !== 'init') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '起動中です',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'ready':
                                if (oldRobotState !== 'ready') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '配達待機状態になりました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'busy':
                                if (oldRobotState !== 'busy') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '移動命令を受け付けました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'movingpos':
                                if (oldRobotState !== 'movingpos') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '移動中です',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'workingpos':
                                if (oldRobotState !== 'workingpos') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '目的地に到着しました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'charging':
                                if (oldRobotState !== 'charging') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '充電中です',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'stop':
                                if (oldRobotState !== 'stop') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '停止しました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'temi':
                        switch (newRobotState) {
                            case 'start':
                                if (oldRobotState !== 'start') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '案内を開始します',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'going':
                                if (oldRobotState !== 'going') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '移動中です',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'obstacle detected':
                                if (oldRobotState !== 'obstacle detected') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '移動中に障害物が検出されました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'node inactive':
                                if (oldRobotState !== 'node inactive') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: 'ノードが非アクティブです',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'calculating':
                                if (oldRobotState !== 'calculating') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '準備中です',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'complete':
                                if (oldRobotState !== 'complete') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '目的地に到着しました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            case 'abort':
                                if (oldRobotState !== 'abort') {
                                    this.notificationList.push({
                                        type: 'info',
                                        robotName: robotName,
                                        message: '中止しました',
                                        date: this.getNow()
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    },

    methods: {
        getSlicedCount(list, num) {
            return list.length - num < 1 ? 0 : list.length - num;
        },

        getNow() {
            const date = new Date();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = ('0' + date.getHours()).slice(-2);
            const minute = ('0' + date.getMinutes()).slice(-2);
            const second = ('0' + date.getSeconds()).slice(-2);
            return `${month}月${day}日 ${hour}:${minute}:${second}`;
        }
    }
};
</script>

<style scoped>
.max-height {
    height: 100%;
}
</style>
