import { onMounted, ref, watch } from 'vue';
import * as warnService from '@/services/warnService';
import { useTimeoutFn } from '@/hooks/core/useTimeout';

const useWarnListData = () => {
    const warnListData = ref([]);

    const fetchSceneCountListData = async () => {
        const params = {};

        const { data = [] } = (await warnService.getList(params)) || {};

        warnListData.value = data.data;
    };

    const pollingInterval = 5000; // 间隔时间，以毫秒为单位

    // 在组件的 setup 函数中使用 useTimeoutFn
    const { readyRef, start } = useTimeoutFn(() => {
        fetchSceneCountListData();
    }, pollingInterval);

    onMounted(async () => {
        await fetchSceneCountListData();

        start();
    });

    // 监听 readyRef 的变化，当 readyRef 变为 true 时，重新开始轮询
    watch(
        readyRef,
        maturity => {
            if (maturity) {
                start();
            }
        },
        { immediate: true }
    );

    return {
        warnListData,
    };
};

export default useWarnListData;
