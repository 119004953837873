//https://open.ys7.com/api/lapp/token/get?appKey=1336abed6ea14269976fee703bf91b01&appSecret=0e65f081ce85c593d9c2eb61c259ecda

import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

const env = process.env;

export const getToken = async params => {
    console.log('env', env);
    const res = await axiosWithoutAuth.postWithBody(
        `https://open.ys7.com/api/lapp/token/get?appKey=${env.VUE_APP_YS_APP_KEY}&appSecret=${env.VUE_APP_YS_APP_SECRET}`,
        {
            // appKey: env.VUE_APP_YS_APP_KEY,
            // appSecret: env.VUE_APP_YS_APP_SECRET,
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
    );

    const { data } = res;

    console.log('data', data);

    return data?.data?.accessToken;
};
