// stores/auth.ts
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core'; // 用于持久化存储

export const useAuthStore = defineStore('auth', () => {
    // 使用 useStorage 来持久化数据到 localStorage
    const isSigin = useStorage('auth-isSignin', true, localStorage);
    const token = useStorage('auth-token', '', localStorage);
    const isAuthenticationExpired = useStorage('auth-isAuthenticationExpired', true, localStorage);
    const YSToken = useStorage(
        'auth-YSToken',
        'at.dzdsatsedpxs7zzh9kgw37mud2c0omf4-1ap1r1nrv1-049yq3s-tn7jpqnvo1',
        localStorage
    );

    // mutations 转换为 actions
    const signIn = (newToken: string) => {
        isSigin.value = true;
        token.value = newToken;
        isAuthenticationExpired.value = false;
    };

    const signOut = () => {
        isSigin.value = false;
        token.value = '';
        isAuthenticationExpired.value = false;
    };

    const authenticationExpired = () => {
        isAuthenticationExpired.value = true;
    };

    const setYSToken = (newToken: string) => {
        YSToken.value = newToken;
    };

    // getters 可以直接使用 computed，但这里直接返回状态即可
    return {
        // state
        isSigin,
        token,
        isAuthenticationExpired,
        YSToken,

        // actions
        signIn,
        signOut,
        authenticationExpired,
        setYSToken,
    };
});
