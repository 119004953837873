<script>
import CallTheCarModal from '@/views/BaseAndRobotOperation/components/CallTheCarModal.vue';
import SendTaskModal from '@/views/BaseAndRobotOperation/components/SendTaskModal.vue';
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';

export default {
    name: 'StatusCard',
    components: { CallTheCarModal, SendTaskModal },
    data() {
        return {
            timeoutId: '',
            robotName: ' ',
            statusName: ' ',
            nextStation: ' ',
            modeName: '',
            speed: 0,
            battery: 0,
        };
    },
    created() {
        this.loadData();
    },
    beforeUnmount() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        openCallTheCarModal() {
            this.$refs.callTheCarModal.showModal();
        },
        openSendTaskModal() {
            this.$refs.sendTaskModal.showModal();
        },
        stopTask: async function() {
            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params } = pocApiConfig;
            const endpoint = `${host}${apis.sendDeviceCmd.path}`;
            const response = await axiosWithoutAuth.postWithBody(endpoint, {
                ...params,
                cmd: 3,
            });

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            }
        },
        loadData: async function() {
            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params = {} } = pocApiConfig;

            const endpoint = `${host}${apis.getRobotStatus.path}?deviceSn=${params.deviceSn}`;
            const response = await axiosWithoutAuth.get(endpoint);

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            } else {
                const { data: res } = response;

                const { data } = res;
                // 结构 [{stationName, status}]
                // 站点状态code-1:等待中，2:行驶中，3:完成
                this.robotName = data.robotName;
                this.statusName = data.statusName;
                this.nextStation = data.nextStation;
                this.modeName = data.modeName;
                this.speed = data.speed;
                this.battery = data.battery;
            }
            this.timeoutId = setTimeout(this.loadData, apis.getStationStatus.pollingInterval);
        },
    },
};
</script>

<template>
    <v-card color="rgba(255,255,255,.1)" flat max-height="650px">
        <v-card-subtitle class="d-flex">
            <v-img
                alt="Task icon"
                contain
                height="20"
                max-height="20"
                max-width="20"
                src="@/assets/icon/robot.svg"
                width="20"
            />
            <span class="ml-2">车辆状态</span>
        </v-card-subtitle>

        <v-divider />
        <v-spacer />

        <v-card class="d-flex flex-column p-4" color="rgba(0,0,0,0)" flat height="650px">
            <div class="d-flex align-center">
                <v-img
                    alt="Task icon"
                    class="mr-3"
                    contain
                    height="20"
                    max-height="20"
                    max-width="20"
                    src="@/assets/icon/robot-green.svg"
                    width="20"
                />
                <span class="text-[#20E9B7] font-light">{{ robotName }}</span>
            </div>

            <v-row class="pa-3 pb-0" no-gutters>
                <v-col class="pr-3 " cols="6">
                    <div class="h-full flex justify-center items-center">
                        <v-img alt="Task icon" contain src="@/assets/img/robocity.png" />
                    </div>
                </v-col>

                <v-col class="pr-3" cols="6">
                    <v-row class="pa-3 pb-0" no-gutters>
                        <v-col class="pr-3 p-3" cols="6">
                            <v-row
                                class="pa-3 pb-0 bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                状态
                            </v-row>
                            <v-row
                                class="pa-3  bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                <span class="text-[#20E9B7]">{{ statusName }}</span>
                            </v-row>
                        </v-col>

                        <v-col class="" cols="6">
                            <v-row
                                class="pa-3 pb-0 bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                下个站点
                            </v-row>
                            <v-row
                                class="pa-3  bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                <!--                                <span class="text-[#20E9B7]">站点A</span>-->
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="pa-3 pb-0" no-gutters>
                        <v-col class="pr-3 p-3" cols="6">
                            <v-row
                                class="pa-3 pb-0 bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                驾驶模式
                            </v-row>
                            <v-row
                                class="pa-3  bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                <span class="text-[#20E9B7]">{{ modeName }}</span>
                            </v-row>
                        </v-col>

                        <v-col class="" cols="6">
                            <v-row
                                class="pa-3 pb-0 bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                速度
                            </v-row>
                            <v-row
                                class="pa-3  bg-[#1E1E1E] justify-center items-center h-[40px]"
                                no-gutters
                            >
                                <span class="text-[#20E9B7]">{{ speed }} km/h</span>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="pa-3 pb-0" no-gutters>
                        <v-col class="pr-3 bg-[#1E1E1E] justify-center items-center " cols="12">
                            <div class="p-3">
                                <v-row
                                    class="pa-3 pb-0 bg-[#1E1E1E] justify-center items-center h-[40px]"
                                    no-gutters
                                >
                                    电量
                                </v-row>
                                <v-row
                                    class="pa-3 pb-0 bg-[#1E1E1E] flex justify-around items-center h-[40px]"
                                    no-gutters
                                >
                                    <v-progress-linear
                                        :buffer-value="battery"
                                        :location="null"
                                        background-opacity="1"
                                        buffer-opacity="1"
                                        class="bg-[#424242] opacity-100 w-[80%]"
                                        color="#20E9B7"
                                        height="12"
                                        max="9"
                                        min="0"
                                        model-value="2"
                                        rounded
                                    ></v-progress-linear>

                                    <span> {{ battery }}% </span>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="pa-3 pb-0 flex justify-end" no-gutters>
                <v-btn
                    :rounded="0"
                    class="text-[green] font-light mr-3 rounded-0  "
                    color="#20E9B7"
                    style="color: #444444;"
                    theme="light"
                    variant="flat"
                    @click="openCallTheCarModal"
                >
                    呼叫
                </v-btn>

                <v-btn
                    :rounded="0"
                    class="text-[#444444] font-light ms-4 rounded-0"
                    color="#424242"
                    style="color: #C0C0C0;"
                    variant="flat"
                    @click="openSendTaskModal"
                >
                    下发任务
                </v-btn>

                <v-btn
                    :rounded="0"
                    class="text-[#444444] font-light ms-4 rounded-0"
                    color="#ca5858"
                    style="color: #ffffff;"
                    variant="flat"
                    @click="stopTask"
                >
                    停止
                </v-btn>

                <CallTheCarModal
                    ref="callTheCarModal"
                    show-call-the-car-modal="showCallTheCarModal"
                />

                <SendTaskModal ref="sendTaskModal" show-send-task-modal="showSendTaskModal" />
            </v-row>
        </v-card>
    </v-card>
</template>

<style scoped></style>
