<template>
    <v-card max-height="7.5vh" class="pa-2 ma-0 d-flex flex-column align-center justify-center">
        <v-card-title class="subtitle-1 pa-0 ma-0">ステータス</v-card-title>
        <v-card-title class="subtitle-1 pa-0 ma-0" :style="getStyle(type, value)">
            {{ getStatus(type, value) }}
        </v-card-title>
    </v-card>
</template>

<script>
import displayConfig from '@/config/DisplayConfig';

export default {
    name: 'StatusInfo',

    components: {},
    props: {
        type: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getStyle(type, value) {
            let color = '#ffffff';
            if (value in displayConfig.color.status[type]) {
                color = displayConfig.color.status[type][value];
            }
            return { color: color };
        },
        getStatus(type, value) {
            let status = value;
            if (value in displayConfig.text.status[type]) {
                status = displayConfig.text.status[type][value];
            }
            return status;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
