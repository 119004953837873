<template>
    <div class="inside-map-component">
        <div id="inside-map-box" class="inside-map-box">
            <div :style="style" class="inside-canvas">
                <div class="point-position">
                    <div
                        v-for="(point, index) in config.points"
                        :key="index"
                        :style="{
                            left: point.left,
                            top: point.top,
                        }"
                        class="point-name point-1"
                    >
                        {{ point.title }}
                    </div>
                </div>
                <canvas id="insideMap" ref="canvasRef"></canvas>
            </div>
        </div>
    </div>
</template>

<script setup>
import useCalcCanvasSize from './compositions/useCalcCanvasSize.js';
import configArr from './config.js';
import { ref, watch } from 'vue';
import useCanvasAnimation from './compositions/useCanvasAnimation';

const config = configArr[2];

const canvasRef = ref();
console.log('config', configArr, config);
// const { pointData } = useRobotStatus(config);

// eslint-disable-next-line no-undef
const props = defineProps({
    lat: {
        type: Number,
        required: true
    },
    lng: {
        type: Number,
        required: true
    },
    angle: {
        type: Number,
        required: true
    }
});

// 使用 ref 来跟踪路径数据
const pathData = ref([
    []
    // [
    //     {
    //         lat: 26.75152587890625,
    //         lng: 106.67151641845703,
    //         angle: 90,
    //     },
    // ],
    // [
    //     {
    //         lat: 26.747591018676758,
    //         lng: 106.67141723632812,
    //         angle: 180,
    //     },
    // ],
    // [
    //     {
    //         lat: 26.751623153686523,
    //         lng: 106.66767120361328,
    //         angle: 0,
    //     },
    // ],
]);

// const pathData = ref([[]]);

console.log('props', props);
// 监听 props 变化并更新路径数据
watch(
    () => [props.lat, props.lng, props.angle],
    ([newLat, newLng, angle]) => {
        console.log('newLat', newLat, 'newLng', newLng);
        if (newLat && newLng) {
            pathData.value[0].push({
                lat: newLat,
                lng: newLng,
                angle: angle
            });
        }
    },
    { immediate: true, deep: true }
);

const { style, scale } = useCalcCanvasSize(config);

// const { pathData } = usePathData();

// console.log('pointData', pathData);
useCanvasAnimation(pathData, config, canvasRef, scale);
</script>

<style scoped>
.inside-map-component {
    //background-color: #ffffff;
    height: 420px;
    width: 100%;
    overflow: hidden;
}

.inside-map-component .inside-map-box {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.inside-map-component .inside-map-box .inside-canvas {
    background-size: 100% 100%;
    position: absolute;
    max-height: 100%;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.inside-map-component .inside-map-box .inside-canvas .point-position {
    font-size: 10px;
    color: black;
}

.inside-map-component .inside-map-box .inside-canvas .point-position .point-name {
    width: fit-content;
    background-color: rgb(32, 233, 183);
    position: absolute;
    padding: 2px 6px;
    border-radius: 8px;
}

.inside-map-component .inside-map-box .inside-canvas .point-position .point-name::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid rgb(32, 233, 183);
    bottom: -8px;
}

.inside-map-component .inside-map-box .inside-canvas #insideMap {
    width: 100%;
    height: 100%;
}

.inside-map-component .floor {
    background-color: #152634;
    position: absolute;
    top: 80px;
    left: 20px;
    opacity: 0.9;
    z-index: 10;
    color: #75beeb;
    border-radius: 4px;
    font-size: 20px;
}

.inside-map-component .select-robot {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    z-index: 10;
}
</style>
