// plugins/notification/index.ts
import { App, createVNode, render } from 'vue';
import NotificationComponent from '@/components/NotificationComponent.vue';

const showNotification = (type: string, message: string, duration: number) => {
    const container = document.createElement('div');
    const vnode = createVNode(NotificationComponent, {
        message,
        type,
        duration,
    });

    render(vnode, container);
    document.body.appendChild(container);
};

export const notification = {
    success(message: string, duration = 3000) {
        showNotification('success', message, duration);
    },

    error(message: string, duration = 3000) {
        // 同上，修改 type 为 'error'
        showNotification('error', message, duration);
    },

    warning(message: string, duration = 3000) {
        // 同上，修改 type 为 'warning'
        showNotification('warning', message, duration);
    },

    info(message: string, duration = 3000) {
        // 同上，修改 type 为 'info'
        showNotification('info', message, duration);
    },
};

export function useNotification() {
    return notification;
}

export default {
    install(app: App) {
        app.config.globalProperties.$notification = notification;
        app.provide('notification', notification);
    },
};
