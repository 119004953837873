const usePointPostionPie = async (config, canvasRef) => {
    //  处理单位换算
    const handlePointUnit = ([x, y]) => [
        config.unitConversionToCmFn(x),
        config.unitConversionToCmFn(y),
    ];

    // 处理等比缩放
    const handlePointScale = ([x, y]) => [config.cmToPxFn(x), config.cmToPxFn(y)];

    // 处理初始位置偏移量
    const handlePointOffset = config.clacOffsetFn;

    // 统一处理点的转换
    const handlePoint = targetPoint => {
        const { lng, lat } = targetPoint;

        // 处理初始位置偏移量
        let point = handlePointOffset([parseFloat(lng), parseFloat(lat)]);

        console.log('handlePointUnit point 处理初始位置偏移量', point);

        // 处理单位换算
        point = handlePointUnit(point);

        // console.log('handlePointUnit point 处理单位换算', point);

        // 处理等比缩放 转换为px
        point = handlePointScale(point);

        return point;
    };

    return {
        handlePoint,
    };
};

export default usePointPostionPie;
