<script lang="ts" setup>
import GunVideoContent from '@/views/DealWarnVideoLive/components/GunVideoContent/index.vue';
import YSVideoContent from '@/views/DealWarnVideoLive/components/YSVideoContent/index.vue';
import { RoomRegionTypeEnum } from '@/enums/RoomRegionTypeEnum';
import { defineExpose, ref } from 'vue';
import { handleOne } from '@/services/warnService';
import AlamProcessEnum from '@/views/DealWarnVideoLive/enums/AlamProcessEnum';

import { useNotification } from '@/plugins/notification';

import { useRoute } from 'vue-router';

const notification = useNotification();

const route = useRoute(); // 获取路由对象

const alertTimeId = route.query.alertTimeId; // 从路由中获取查询参数

const show = ref(false);
const alertTimeData = ref();

const emits = defineEmits(['updateCurrentWarn']);

const dealWithWarnVideo = async () => {
    await handleOne(alertTimeData.value.alertTimeId);
    notification.success('操作成功！');

    // 如果当前的报警ID是处理的报警ID 则更新状态
    if (alertTimeData.value.alertTimeId === alertTimeId) {
        emits('updateCurrentWarn', {
            ...alertTimeData,
            alarmProcess: AlamProcessEnum.PROCESS,
        });
    }

    closeModal();
};

// 外部可以打开弹窗 内部可以关闭弹窗
const openModal = item => {
    alertTimeData.value = item;
    show.value = true;
};

const closeModal = () => {
    show.value = false;
};

defineExpose({ openModal }); // 暴露方法给父组件
</script>

<template>
    <v-dialog v-model="show" width="600px">
        <v-card v-if="show">
            <v-toolbar>
                <v-toolbar-title>监控视频</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="closeModal"></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <YSVideoContent
                v-if="alertTimeData.roomRegionType === RoomRegionTypeEnum.PRIVATE"
            ></YSVideoContent>

            <GunVideoContent v-else></GunVideoContent>

            <v-card-actions v-if="alertTimeData.alarmProcess === AlamProcessEnum.UNPROCESS">
                <v-spacer></v-spacer>

                <v-btn
                    :rounded="0"
                    class="text-[#C0C0C0] font-light ms-4 rounded-0"
                    color="#4558C8"
                    variant="flat"
                    @click="dealWithWarnVideo"
                >
                    处理
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
