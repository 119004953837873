<template>
    <v-dialog v-model="dialog" max-width="400px">
        <v-card>
            <v-card-title class="headline">呼叫车辆</v-card-title>
            <v-card-text>
                <v-form>
                    <v-select
                        v-model="selectedStation"
                        :items="stations"
                        item-title="text"
                        item-value="value"
                        label="终点"
                    ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :rounded="0"
                    class="text-[#C0C0C0] font-light ms-4 rounded-0"
                    color="#424242"
                    variant="flat"
                    @click="cancel"
                >
                    取消
                </v-btn>
                <v-btn
                    :rounded="0"
                    class="text-[#C0C0C0] font-light ms-4 rounded-0"
                    color="#4558C8"
                    variant="flat"
                    @click="confirm"
                >
                    确认
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import apiConfig from '@/config/apiConfig';
import axiosWithoutAuth from '@/lib/axiosWithoutAuth';
import stations from '../const/stations';

export default {
    props: {},
    data() {
        return {
            dialog: false,
            selectedStation: null,
            stations
        };
    },
    methods: {
        showModal() {
            this.dialog = true;
        },
        cancel() {
            this.dialog = false;
        },
        confirm: async function() {
            // 在这里添加确认逻辑
            const { poc: pocApiConfig } = apiConfig;
            const { host, apis, params } = pocApiConfig;
            const endpoint = `${host}${apis.sendTask.path}`;
            const response = await axiosWithoutAuth.postWithBody(endpoint, {
                ...params,
                stationIdList: [this.selectedStation]
            });

            if ('errors' in response) {
                console.log('error occurred in getReceptionStatus calling', response);
            }

            this.dialog = false;
        }
    }
};
</script>

<style scoped>
.headline {
    display: flex;
    justify-content: space-between;
}
</style>
