<template>
    <v-card color="rgba(255,255,255,.1)" flat max-height="700px">
        <v-card-subtitle class="d-flex">
            <FloorGuideIcon />
            <div class="pl-2">フロアガイド</div>
        </v-card-subtitle>
        <v-divider />
        <v-spacer />

        <v-card flat color="rgba(0,0,0,0)">
            <v-row no-gutters class="mt-2 card-image-container">
                <MapImage class="card-image" contain />
                <div v-for="(item, key) in robots" :key="item.id">
                    <RobotLocation
                        :direction="style[key]['direction']"
                        :color="style[key]['color']"
                        :style="style[key]['position']"
                        class="robot"
                    />
                </div>
                <LocationIcon class="robot" :style="getLocation()" />
            </v-row>
            <v-row no-gutters class="pt-0">
                <div class="ml-5 mb-5 names">
                    <LocationIcon :width="26" :height="22" />
                    <div class="ml-1 name">現在地</div>
                    <div v-for="(item, key) in robots" :key="item.id" class="ml-5 name">
                        <RobotIcon :width="26" :height="22" :color="style[key]['color']" />
                        <div class="ml-1" :style="`color: ${style[key]['color']}`">
                            {{ item.name.value }}
                        </div>
                    </div>
                </div>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
import RobotLocation from '@/components/floor_map/RobotLocation';
import FloorGuideIcon from '@/components/icon/FloorGuide';
import LocationIcon from '@/components/icon/Location';
import MapImage from '@/components/icon/Map';
import RobotIcon from '@/components/icon/Robot';
import displayConfig from '@/config/DisplayConfig';

export default {
    name: 'FloorMap',

    components: { LocationIcon, RobotLocation, FloorGuideIcon, RobotIcon, MapImage },
    props: {
        robots: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            style: []
        };
    },
    watch: {
        robots: function(newVal, oldVal) {
            newVal.forEach((val, key) => {
                // reset style
                this.style[key] = [];
                this.style[key].position = [];
                this.style[key].direction = 0;
                // set robot color
                this.style[key].color = this.getColor(key);

                const x = val.x.value;
                const y = val.y.value;
                let params = {};

                try {
                    let type = val.type;
                    if (val.type === 'temi') {
                        // temi
                        const array = val.id.split(':');
                        type = val.type + array[array.length - 1];
                        // translate radian to degree
                        newVal[key].direction.value = newVal[key].direction.value * (180 / Math.PI);
                    }
                    if (type in displayConfig.translateParameters) {
                        params = displayConfig.translateParameters[type];
                    } else {
                        return;
                    }

                    // reset current angle
                    if (oldVal[key].direction.value > 10000) {
                        oldVal[key].direction.value = 0;
                    }

                    newVal[key].direction.value = this.normalizeAngle(newVal[key].direction.value);
                    // add the minimum movement angle.
                    newVal[key].direction.value =
                        oldVal[key].direction.value +
                        ((((newVal[key].direction.value - oldVal[key].direction.value) % 360) +
                            540) %
                            360) -
                        180;
                    // multiply angle by -1.
                    // because the direction of rotation is opposite between the robot and UI.
                    this.style[key].position = {
                        left: `${params.a * x + params.b * y + params.e}%`,
                        bottom: `${params.c * x + params.d * y + params.f}%`
                    };
                    // TODO: add rotate parameter
                    this.style[key].direction = -1 * newVal[key].direction.value + params.rot;
                    this.style[key].color = this.getColor(key);
                } catch (e) {
                    console.error('position calculation failed. target: ', val);
                    console.error(e.message);
                }
            });
        }
    },
    methods: {
        normalizeAnglePositive(angle) {
            const n = 360.0;
            return ((angle % n) + n) % n;
        },
        // Normalize the angle to be -180 to +180 deg.
        normalizeAngle(angle) {
            let a = this.normalizeAnglePositive(angle);
            if (a > 180.0) {
                a -= 360.0;
            }
            return a;
        },
        getColor(index) {
            if (index < displayConfig.color.theme.length) {
                return displayConfig.color.theme[index];
            }
            return displayConfig.color.theme[0];
        },
        getLocation() {
            if (
                typeof this.$route.query.x !== 'undefined' &&
                typeof this.$route.query.y !== 'undefined'
            ) {
                return {
                    left: `${this.$route.query.x}%`,
                    bottom: `${this.$route.query.y}%`
                };
            }
            return {
                left: `${displayConfig.location.Home.left}%`,
                bottom: `${displayConfig.location.Home.bottom}%`
            };
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.robot {
    position: absolute;
    cursor: pointer;
    transition: 1s;
}
.card-image-container {
    display: flex;
    align-content: center;
    height: 600px !important;
    max-width: 100% !important;
}
.card-image {
    max-height: 95% !important;
    width: 100%;
}
.names {
    max-width: 100%;
    display: flex;
}
.name {
    display: flex;
    vertical-align: bottom;
}
</style>
