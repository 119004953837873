<template>
    <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        id="レイヤー_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 16"
        style="enable-background: new 0 0 20 16"
        :width="width"
        :height="height"
        :fill="color"
        xml:space="preserve"
    >
        <g id="グループ_6" transform="translate(0.25)">
            <g>
                <g id="グループ_5" transform="translate(-0.25)">
                    <path
                        id="パス_2"
                        d="M0,8v4c0,0.55,0.45,1,1,1h1V7H1C0.45,7,0,7.45,0,8 M14.5,3H11V1c0-0.55-0.45-1-1-1S9,0.45,9,1v2H5.5
                    C4.12,3,3,4.12,3,5.5V14c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V5.5C17,4.12,15.88,3,14.5,3 M8,13H6v-1h2V13z M7,9.25
                    C6.31,9.25,5.75,8.69,5.75,8S6.31,6.75,7,6.75S8.25,7.31,8.25,8S7.69,9.25,7,9.25 M11,13H9v-1h2V13z M14,13h-2v-1h2V13z M13,9.25
                    c-0.69,0-1.25-0.56-1.25-1.25S12.31,6.75,13,6.75S14.25,7.31,14.25,8S13.69,9.25,13,9.25 M19,7h-1v6h1c0.55,0,1-0.45,1-1V8
                    C20,7.45,19.55,7,19,7"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'RobotIcon',
    props: {
        width: {
            type: [Number, String],
            default: 20
        },
        height: {
            type: [Number, String],
            default: 16
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
