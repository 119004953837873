// import App from './App.vue';
// import router from './router';
// import store from './store';
// import vuetify from './plugins/vuetify';
// import AuthService from './lib/AuthWithMsal.js';
// import LiveViewCloudVideo from './lib/LiveViewCloudVideo';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store/';
import vuetify from './plugins/vuetify';
import { createPinia } from 'pinia';
import NotificationPlugin from './plugins/notification';

import './style.css';

// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//     faBolt,
//     faCaretSquareRight,
//     faCheck,
//     faCircle,
//     faExclamationTriangle,
//     faExternalLinkAlt,
//     faPlay,
//     faRobot,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const app = createApp(App);

// library.add(
//     faRobot,
//     faBolt,
//     faPlay,
//     faCaretSquareRight,
//     faExclamationTriangle,
//     faCheck,
//     faExternalLinkAlt,
//     faCircle,
// );
// app.component('font-awesome-icon', FontAwesomeIcon);

app.use(NotificationPlugin);

// 使用方式
// Vue.config.productionTip = false;

// Vue.prototype.$AuthService = new AuthService();

// LiveViewCloudVideo.setup();
//
// new Vue({
//     router,
//     store,
//     vuetify,
//     render: h => h(App),
// }).$mount('#app');

app.use(router);
// app.use(store);
app.use(createPinia());
app.use(vuetify);
app.mount('#app');

// const app = createApp()
