<script setup>
import TCardComponent from '@/components/TCardComponent/index.vue';
import StatusCard from './components/StatusCard.vue';
import RouteMap from './components/RouteMap/index.vue';
import WarnList from './components/WarnList/index.vue';
import WarnVideoModal from '@/views/DealWarnVideoLive/components/WarnVideoModal/index.vue';
import useWarnInfoData from '@/views/DealWarnVideoLive/hooks/useWarnInfoData';
import useRobotInfoData from '@/views/DealWarnVideoLive/hooks/useRobotInfoData';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useNotification } from '@/plugins/notification';

const notification = useNotification();
const route = useRoute(); // 获取路由对象

// 路由参数 alertTimeId-告警时间id
const alertTimeId = route.query.alertTimeId;

// 获取当前告警信息数据
const { currentWarnData, updateCurrentWarn } = useWarnInfoData(alertTimeId);

// 获取机器人信息数据
const { robotInfo } = useRobotInfoData();

// 报警视频弹窗组件引用
const warnVideoModalRef = ref(null);

const openVideoModal = item => {
    warnVideoModalRef.value.openModal(item);
};
</script>

<template>
    <div class="max-width">
        <v-row class="pa-3 pb-0" no-gutters>
            <v-col class="pr-3" cols="7">
                <TCardComponent>
                    <template #title>
                        <v-img
                            alt="Task icon"
                            contain
                            height="20"
                            max-height="20"
                            max-width="20"
                            src="@/assets/icon/task.svg"
                            width="20"
                        />
                        <span class="ml-2">车辆任务一览</span>
                    </template>
                    <RouteMap
                        :currentWarnData="currentWarnData"
                        :robotInfo="robotInfo"
                        @openVideoModal="openVideoModal"
                    />
                </TCardComponent>
            </v-col>
            <v-col cols="5">
                <TCardComponent>
                    <template #title>
                        <v-img
                            alt="Task icon"
                            contain
                            height="20"
                            max-height="20"
                            max-width="20"
                            src="@/assets/icon/robot.svg"
                            width="20"
                        />
                        <span class="ml-2">告警记录</span>
                    </template>

                    <WarnList @openVideoModal="openVideoModal" />
                    <StatusCard
                        :currentWarnData="currentWarnData"
                        :robotInfo="robotInfo"
                        @openVideoModal="openVideoModal"
                    />
                </TCardComponent>
            </v-col>
        </v-row>

        <WarnVideoModal ref="warnVideoModalRef" @updateCurrentWarn="updateCurrentWarn" />
    </div>
</template>

<style scoped></style>
