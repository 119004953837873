<template>
    <div class="icon">
        <ArrowIcon
            class="arrow"
            :width="32"
            :height="28"
            :color="color"
            :style="getStyle(direction)"
        />
        <RobotIcon :width="32" :height="28" :color="color" />
    </div>
</template>

<script>
import ArrowIcon from '@/components/icon/Arrow';
import RobotIcon from '@/components/icon/Robot';

export default {
    name: 'RobotLocation',
    components: { ArrowIcon, RobotIcon },
    props: {
        direction: { type: Number, required: true },
        color: { type: String, default: 'currentColor' }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getStyle(direction) {
            return {
                transform: `translate(0%, -100%) rotate(${direction}deg)`
            };
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.arrow {
    position: absolute;
    transform-origin: center 150%;
}
.name {
    display: inline-block;
    vertical-align: top;
}
</style>
