const stations = [
    {
        text: '会议室1',
        value: 'e0a46dce097d47808e70c03756f44d43',
    },
    {
        text: '取餐点',
        value: '70b34a828bef45ea92080c310b474553',
    },
];
export default stations;
