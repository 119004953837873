<template>
    <div class="inside-map-component">
        <div id="inside-map-box" class="inside-map-box">
            <div :style="style" class="inside-canvas">
                <div class="point-position">
                    <div
                        v-for="(point, index) in config.points"
                        :key="index"
                        :style="{
                            left: point.left,
                            top: point.top,
                        }"
                        class="point-name point-1"
                    >
                        {{ point.title }}
                    </div>
                </div>

                <v-dialog v-model="dialog" height="480px" width="600px">
                    <v-card v-if="dialog">
                        <v-toolbar>
                            <v-toolbar-title>监控视频</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-toolbar-items>
                                <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                    </v-card>
                </v-dialog>
                <div class="rounded-2xl absolute left-[81%] top-[35%]">
                    <v-img
                        v-if="
                            currentWarnData.roomRegionType !== RoomRegionTypeEnum.PRIVATE &&
                                currentWarnData.alarmProcess === AlamProcessEnum.UNPROCESS
                        "
                        alt="Task icon"
                        contain
                        height="20"
                        max-height="20"
                        max-width="20"
                        src="@/assets/img/camera-warn.png"
                        width="20"
                        @click="openVideoModal(true)"
                    />

                    <v-img
                        v-else
                        alt="Task icon"
                        contain
                        height="20"
                        max-height="20"
                        max-width="20"
                        src="@/assets/img/camera.png"
                        width="20"
                        @click="openVideoModal"
                    />
                </div>
                <canvas id="insideMap" ref="canvasRef"></canvas>
            </div>
        </div>
    </div>
</template>

<script setup>
import useCalcCanvasSize from './compositions/useCalcCanvasSize.js';
import configArr from './config.js';
import { ref, watch } from 'vue';
import useCanvasAnimation from './compositions/useCanvasAnimation';
import { RoomRegionTypeEnum } from '@/enums/RoomRegionTypeEnum.ts';
import { toRefs } from '@vueuse/core';
import AlamProcessEnum from '@/views/DealWarnVideoLive/enums/AlamProcessEnum'; // 引入 useRoute 钩子

//  从query中接收warnId=1&camareId=1参数
const config = configArr[2];

const canvasRef = ref();
console.log('config', configArr, config);

// eslint-disable-next-line no-undef
const props = defineProps({
    robotInfo: {
        type: Object,
        default: () => ({}),
    },
    currentWarnData: {
        type: Object,
        default: () => ({}),
    },
});

const emits = defineEmits(['openVideoModal', 'updateCurrentWarn']);

const { currentWarnData } = toRefs(props);

const openVideoModal = isPublic => {
    emits('openVideoModal', {
        ...currentWarnData.value,
        roomRegionType: isPublic ? RoomRegionTypeEnum.PUBLIC : currentWarnData.value.roomRegionType,
    });
};

// 使用 ref 来跟踪路径数据
const pathData = ref([
    [],
    // [
    //     {
    //         lat: 26.75152587890625,
    //         lng: 106.67151641845703,
    //         angle: 90,
    //     },
    // ],
    // [
    //     {
    //         lat: 26.747591018676758,
    //         lng: 106.67141723632812,
    //         angle: 180,
    //     },
    // ],
    // [
    //     {
    //         lat: 26.751623153686523,
    //         lng: 106.66767120361328,
    //         angle: 0,
    //     },
    // ],
]);

// const pathData = ref([[]]);

console.log('props', props);
// 监听 props 变化并更新路径数据
watch(
    () => props.robotInfo,
    ({ lat, lng, angle }) => {
        console.log('newLat', lat, 'newLng', lng);
        if (lat && lng) {
            pathData.value[0].push({
                lat: lat,
                lng: lng,
                angle: angle,
            });
        }
    },
    { immediate: true, deep: true }
);

const { style, scale } = useCalcCanvasSize(config);

// const { pathData } = usePathData();

// console.log('pointData', pathData);
useCanvasAnimation(pathData, config, canvasRef, scale);
</script>

<style scoped>
.inside-map-component {
    //background-color: #ffffff;
    height: 420px;
    width: 100%;
    overflow: hidden;
}

.inside-map-component .inside-map-box {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.inside-map-component .inside-map-box .inside-canvas {
    background-size: 100% 100%;
    position: absolute;
    max-height: 100%;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.inside-map-component .inside-map-box .inside-canvas .point-position {
    font-size: 10px;
    color: black;
}

.inside-map-component .inside-map-box .inside-canvas .point-position .point-name {
    width: fit-content;
    background-color: rgb(32, 233, 183);
    position: absolute;
    padding: 2px 6px;
    border-radius: 8px;
}

.inside-map-component .inside-map-box .inside-canvas .point-position .point-name::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid rgb(32, 233, 183);
    bottom: -8px;
}

.inside-map-component .inside-map-box .inside-canvas #insideMap {
    width: 100%;
    height: 100%;
}

.inside-map-component .floor {
    background-color: #152634;
    position: absolute;
    top: 80px;
    left: 20px;
    opacity: 0.9;
    z-index: 10;
    color: #75beeb;
    border-radius: 4px;
    font-size: 20px;
}

.inside-map-component .select-robot {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    z-index: 10;
}
</style>
